import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, getCSSVarString, ICON } from "Libs/themes";

const getBackgroundColor = type => {
  switch (type) {
    case "info":
      return"var(--section-light-pink-background-color,var(--section-light-pink,var(--light-pink)))";
    case "warning":
      return"var(--section-gold-background-color,var(--section-gold,var(--gold)))";
    case "critical":
      return"var(--icon-red-background-color,var(--icon-red,var(--red)))";
    default:
      return"var(--section-dark-tint-background-color,var(--section-dark-tint,var(--dark-tint)))";
  }
};

export const getFontColor = type => {
  switch (type) {
    case "info":
      return"var(--section-wine-color,var(--section-wine,var(--wine)))";
    case "warning":
      return"var(--section-dark-color,var(--section-dark,var(--dark)))";
    case "critical":
      return"var(--section-snow-color,var(--section-snow,var(--snow)))";
    default:
      return "#f5f7fa";
  }
};

const getHighLightColor = (type, theme) => {
  switch (type) {
    case "info":
      return theme.bannerInfoBackgroundFocusColor;
    case "warning":
      return "#E1A419";
    case "critical":
      return "#E03124";
    default:
      return "transparent";
  }
};

export const Content = styled.div`
  line-height: 40px;
  align-items: center;
  color: inherit;
  font-size: 14px;
  font-weight: ${semiBoldAlias};
  a {
    color: inherit;
    font-weight: 600;
    position: relative;
    font-size: inherit;
    text-decoration: none;
    &:after {
      content: "";
      height: 1px;
      width: 100%;
      background: #fff;
      position: absolute;
      bottom: -1px;
      left: 0;
    }
  }

  strong {
    display: inline-block;
    padding: 0 8px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 2px;
  }

  > svg {
    margin: 0 16px;
    vertical-align: middle;
  }

  .link-button {
    margin: 0 16px;
    a {
      height: 24px;
      line-height: 24px;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.8px;
      padding: 0 16px;
      &:after {
        content: none;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${props => getBackgroundColor(props.type, props.theme)};
  color: ${props => getFontColor(props.type, props.theme)};
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    min-height: 40px;
  }
  .text {
    display: none;
  }
  ${Content} {
    a:after {
      background: ${props => getFontColor(props.type, props.theme)};
    }

    strong {
      background: ${props => getHighLightColor(props.type, props.theme)};
    }
  }
  @media (min-width: 768px) {
    .text {
      display: inline;
    }
    .mobile-text {
      display: none;
    }
  }
`;

export const CloseBtnWrapper = styled.div`
  position: absolute;
  right: 8px;

  a {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  svg > g > g {
    fill:var(--icon-snow-fill,var(--icon-snow,var(--snow)));
  }

  &:hover svg > g > g {
    fill:var(--icon-snow-fill,var(--icon-snow,var(--snow)));
  }
`;

export const Lart = styled.img`
  width: 74px;
  height: 19px;
  margin-right: 20px;
`;

export const Rart = styled(Lart)`
  margin-right: 0;
  margin-left: 20px;
`;
