import styled, { css as styledCSS } from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

export const LoadingWrapper = styled.span`
  display: flex;
  align-items: center;

  ${props =>
    props.iconOnly &&
    styledCSS`
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 50px;
    `};
`;

export const MessageWrapper = styled.span`
  display: inline-block;
  color:var(--section-skye-color,var(--section-skye,var(--skye)));
  ${semiBoldAlias};
  &:first-letter {
    text-transform: uppercase;
  }
`;
