import styled from "styled-components";

import {
  css,
  SECTION,
  MENU,
  LINK,
  MENU_ITEM,
  getCSSVarString
} from "Libs/themes";

export const MenuLayout = styled.div`
  padding: 0;
  .menu-item {
    display: block;
    width: auto;
  }
  .rcs-custom-scroll {
    .rcs-custom-scrollbar {
      opacity: 1;
      .rcs-inner-handle {
        background-color:var(--section-granite-background-color,var(--section-granite,var(--granite)));
      }
    }
    .rcs-inner-container {
      padding: 8px 0;
      box-sizing: border-box;
      > div {
        padding: 0 8px;
      }
    }
    .rcs-inner-handle {
      fill:var(--section-dark-tint-fill,var(--section-dark-tint,var(--dark-tint)));
      width: 4px;
      border-radius: 4px;
      opacity: 1;
    }
  }
  .project-link {
    display: inline;
    span {
      display: inline;
    }
  }
  .blue {
    color:var(--menu-skye-color,var(--menu-skye,var(--skye)));
    background: transparent;
  }
  a {
    text-decoration: none;
    color: var(--link-slate-color,var(--link-slate,var(--slate))) !important;
    position: relative;
    span.highlight {
      color:var(--link-skye-color,var(--link-skye,var(--skye)));
    }
  }
`;

export const Active = styled.span`
  position: absolute;
  top: 14px;
  left: 4px;
  height: 4px;
  width: 4px;
  border-radius: 4px;
  background-color:var(--menu-item-skye-light-background-color,var(--menu-item-skye-light,var(--skye-light)));
`;

export const Empty = styled.div`
  color:var(--section-night-color,var(--section-night,var(--night)));
  line-height: 32px;
  padding: 0 16px;
  font-size: 15px;
`;

export const HeaderText = styled.li`
  color: #98a0ab;
  font-size: 13px;
  padding: 0px 16px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
