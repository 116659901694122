import styled from "styled-components";

import { CloseButton as BaseCloseButton } from "ds/Button";

export const SubHeading = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

export const OperationDescription = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
`;

export const VariableWrapper = styled.div``;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VariableInput = styled.div`
  display: flex;
  gap: 16px;
  .input-field {
    width: 100%;
    flex-shrink: inherit;
  }
  .variable-name {
    flex: 2;
  }
  .variable-value {
    flex: 3;
  }
`;

export const CloseButton = styled(BaseCloseButton)`
  position: relative;
  top: 34px;
`;

export const AddVariableHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
