import styled from "styled-components";

import { css, TAB, LINK, getCSSVarString } from "Libs/themes";

import SubNavBar from "Components/SubNavBar";
import * as SNavBar from "Components/SubNavBar/styles";
import * as STabs from "Components/SubNavBar/Tabs/styles";

// TODO: Find solution for background-color: var(--link-snow-border-color,var(--link-snow,var(--snow)));
export const NavBar = styled(SubNavBar)`
  ${SNavBar.SubNavBarLayout} {
    height: 34px;
    min-height: 34px;
    max-height: 34px;
    padding: 0;

    a:not(.button) {
      font-size: 13px;
      &:hover,
      &:focus,
      &:active {
        color:var(--tab-snow-color,var(--tab-snow,var(--snow)));
      }

      &.active:before {
        background-color: var(--tab-snow-border-color,var(--tab-snow,var(--snow)));
      }
    }

    @media (min-width: 768px) {
      ${SNavBar.SubNavBarTabWrapper} {
        position: absolute;
        width: fit-content;
        padding-top: 18px;
        padding-left: 0;
        box-sizing: content-box;
      }

      a:not(.button) {
        padding-left: 0;
        &.active {
          &:before {
            left: 0;
            width: calc(100% - 10px);
          }
        }
      }
    }
  }

  ${STabs.DropdownTab}, ${STabs.Tab} {
    a:not(.button) {
      color:var(--link-granite-color,var(--link-granite,var(--granite)));
      &.active {
        color:var(--link-snow-color,var(--link-snow,var(--snow)));
      }
    }
  }
`;
