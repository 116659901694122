import { useCallback } from "react";
import { useSelector } from "react-redux";

import useDecodedParams from "./useDecodedParams";

const useSelectorWithUrlParams = (selector, params = {}) => {
  const urlsParams = useDecodedParams();
  const props = { ...urlsParams, ...params };

  const memoizeSelector = useCallback(state => selector(state, props), [props]);

  return useSelector(memoizeSelector);
};

export default useSelectorWithUrlParams;
