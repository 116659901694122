import React, { Children, FC } from "react";

import * as S from "./SecondaryLink.styles";
import { Props } from "./Button";

/**
 * Besides the color difference, secondary links have a different content
 * distribution than regular links.
 *
 * SecondaryLinks take an array of children. If there are at least two children,
 * it will behave like a mixed link but the underline will only cover the text.
 * If there's jutst one child it will render a regular link with extra padding
 * and different hover and focus styles.
 */
const SecondaryLink: FC<Props> = ({ children, ...props }) => {
  const childrenArray = Children.toArray(children);

  // Assume the first child is going to be the icon
  const icon = childrenArray.length > 1 && childrenArray[0];
  // Assume the rest will be the text
  const text =
    childrenArray.length > 1 ? childrenArray.slice(1) : childrenArray;
  return (
    <S.Layout {...props}>
      {icon && <S.Icon>{icon}</S.Icon>}
      <S.Text hasIcon={!!icon}>{text}</S.Text>
    </S.Layout>
  );
};

export default SecondaryLink;
