import React from "react";

export const Normal: React.FC = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8171 15.6448L11.4697 0.800929C10.8019 -0.266976 9.24371 -0.266976 8.57592 0.800929L0.228535 15.6448C-0.439255 16.7127 0.451132 17.9942 1.67542 17.9942H18.3702C19.5945 18.101 20.3736 16.7127 19.8171 15.6448ZM10 12C9.44772 12 9 11.5523 9 11V6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6V11C11 11.5523 10.5523 12 10 12ZM10 16C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14C9.44771 14 9 14.4477 9 15C9 15.5523 9.44771 16 10 16Z"
        fill="#EDA900"
      />
    </svg>
  );
};

export default Normal;
