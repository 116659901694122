import styled from "styled-components";
import { css, MENU_ITEM, SECTION, getCSSVarString } from "Libs/themes";

export const RouteList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const RoutesWrapper = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: ${({ hasViewAll }) => (hasViewAll ? 0 : "16px")};
`;

export const ViewAll = styled.div`
  text-align: center;
  padding-top: 9px;
  padding-bottom: 12px;
  border-top: 1px solid
    var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  margin-top: 12px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;

  && a {
    line-height: 19px;
  }
`;

export const RouteLink = styled.li`
  list-style: none;
  .route-link {
    white-space: nowrap;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    .path {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.secure {
      display: flex;
      align-items: center;
    }
    .secure-icon {
      display: flex;
      align-items: center;
      height: 24px;
      width: 24px;
      min-width: 24px;
      margin-left: 10px;
      svg {
        width: 100%;
        height: auto;
      }
    }
    &:hover {
      text-decoration: none;
      color:var(--menu-item-skye-color,var(--menu-item-skye,var(--skye)));
    }
    &:focus,
    &:active {
      margin-left: -1px;
    }
    .chevron > svg {
      transform: rotate(-90deg);
    }
  }
`;
