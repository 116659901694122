import { css, useTheme } from "styled-components";

import { palette } from "Libs/themes/legacyThemes";

export const semiBoldAlias = ({ fontWeight = 600 } = {}) => `
    font-family: "Open sans", sans-serif;
    font-weight: ${fontWeight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `;

export const shadows = {
  sm: css`
    box-shadow: 0px 1px 4px rgba(152, 160, 171, 0.4);
  `,
  md: css`
    box-shadow: 0px 4px 8px rgba(152, 160, 171, 0.4);
  `,
  lg: css`
    box-shadow: 0px 6px 24px rgba(152, 160, 171, 0.4);
  `,
  xl: css`
    box-shadow: 0px 6px 44px rgba(152, 160, 171, 0.6);
  `
};

export const breakpoints = [
  "32rem", // 512
  "48rem", // 768
  "64rem", // 1024
  "80rem", // 1280
  "96rem", // 1536
  "112rem", // 1792
  "128rem" // 2048
];

export const vwGrid = {
  mobile: "calc((100vw - 48px + 24px) / 6 - 24px)",
  standard: "calc((100vw - 80px + 32px) / 12 - 32px)",
  wide: "calc((100vw - 80px + 32px) / 12 - 32px)",
  extraWide: "calc((100vw - 120px + 32px) / 12 - 32px)"
};

/**
 *
 * Usage
 *
 * ```styles.js
 *  import styled from "styled-components";
 *
 *  import { makePalette } from "Libs/theme";
 *
 *  const theme = makePalette((palette, props) => ({
 *    section_background: {
 *      default: palette.snow,
 *      dark: palette.ebony
 *    },
 *    section_color: {
 *      default: props.isActive ? palette.danger : "transparent"
 *    }
 *  }))
 *
 * const Section = styled.section`
 *  background-color: ${theme.section_background};
 * `;
 * ```
 */
export const makePalette = getValues =>
  Object.keys(getValues({}, {})).reduce(
    (acc, key) => ({
      ...acc,
      [key]:
        () =>
        ({ theme, ...props }) => {
          const values = getValues({ ...palette, ...theme }, props);

          return values[key][theme.name] || values[key]["default"];
        }
    }),
    {}
  );

/**
 *
 * Usage
 *
 * ```Component.js
 *  import { usePalette } from "Libs/theme";
 *  const Component = props => {
 *    const theme = usePalette(palette => {
 *      icon {
 *        default: palette.primary,
 *        contrast: "#ffffff"
 *      }
 *    });
 *
 *    return <Icon color={theme.icon} />
 *  };
 * ```
 */
export const usePalette = getValues => {
  const theme = useTheme();
  const values = getValues({ ...palette, ...theme }, {});

  return Object.entries(values).reduce((acc, [key, value]) => {
    const v = value[theme.name] || value["default"];
    return {
      ...acc,
      [key]: v && typeof v === "object" ? v[800] : v
    };
  }, {});
};
