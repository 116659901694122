import styled, { css } from "styled-components";
import { Button } from "..";

import {
  WarningIconBackground,
  WarningIconForeground
} from "../Icons/variants/WarningIcon";

import { Props } from "./Banner";

export const Layout = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  height: 40px;
  padding-right: 40px;
  padding-left: 40px;

  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  ${({ priority }) => css`
    background-color: var(--banner-${priority}-background, #000);
    color: var(--banner-${priority}-foreground, #fff);
  `};

  a,
  ${Button} {
    color: inherit;
    font-weight: 600;
    :after {
      background-color: var(
        --banner-${({ priority }) => priority}-foreground,
        #fff
      );
    }
  }

  ${WarningIconForeground} {
    fill: var(--banner-${({ priority }) => priority}-background, #000);
  }

  ${WarningIconBackground} {
    fill: var(--banner-${({ priority }) => priority}-foreground, #fff);
  }
`;

export const TextContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
