import styled, { css as styledCSS } from "styled-components";
import { Link as LinkUnstyled } from "react-router-dom";

import { semiBoldAlias } from "Libs/theme";
import { css, LINK, ICON, SECTION, getCSSVarString, BUTTON } from "Libs/themes";

import ActionDropdownUnstyled from "Components/ActionDropdown";
import InfoDialog from "Components/InfoDialog";

export const Wrapper = styled(LinkUnstyled)`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  height: 32px;
  line-height: 32px;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color:var(--link-skye-light-background-color,var(--link-skye-light,var(--skye-light)));
    border-radius: 4px;
    outline: none;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 2px;
`;

export const IconRootWrapper = styled(IconWrapper)`
  background-color:var(--icon-java-background-color,var(--icon-java,var(--java)));
`;

export const Title = styled.div`
  flex: 1;
  overflow: hidden;
  padding-right: 20px;
  font-size: 15px;
  color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
  ${({ environmentType }) =>
    environmentType !== "development" &&
    styledCSS`
      ${semiBoldAlias};
  `}

  ${({ status }) =>
    status === "inactive" &&
    styledCSS`
      color:var(--section-granite-color,var(--section-granite,var(--granite)));
  `}

  > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  mark.blue {
    background: none;
    color:var(--section-skye-color,var(--section-skye,var(--skye)));
  }
`;

const Info = styled.div`
  width: 100px;
  margin-right: 40px;
  ${semiBoldAlias};
  font-size: 13px;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

export const Type = styled(Info)`
  text-transform: capitalize;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const CreatedAt = styled(Info)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Deployment = styled(Info)`
  position: relative;
  width: 148px;
  margin-right: 6px;

  @media (max-width: 768px) {
    width: auto;
  }
`;

export const DeploymentDetails = styled(ActionDropdownUnstyled)`
  button {
    width: 134px;
    padding: 0 6px 0 16px !important;
    ${semiBoldAlias};
    font-size: 13px;
    color:var(--section-granite-color,var(--section-granite,var(--granite)));

    &:focus,
    &:hover,
    &.open {
      border: none;
      outline: none;
      box-shadow: none;
      background-color:var(--button-grey-background-color,var(--button-grey,var(--grey)));
      color:var(--button-slate-color,var(--button-slate,var(--slate)));

      svg {
        transform: none;
      }
    }

    @media (max-width: 768px) {
      width: auto;
      .text {
        display: none;
      }
    }
  }
`;

export const InProgress = styled.div`
  display: flex;
  width: 140px;
  height: 30px;
  padding: 0 6px 0 16px;
  align-items: center;
  box-sizing: border-box;
  > span {
    margin-left: auto;
  }
`;
export const InProgressText = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const DeploymentInfo = styled.div`
  font-size: 14px;
  line-height: 24px;
`;

export const CommitSha = styled.div`
  display: block;
  margin: 8px 0 2px 0;
  font-size: 14px;
  line-height: 24px;
`;

export const DeployedAt = styled.div`
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 16px;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

export const ActionDropdown = styled(ActionDropdownUnstyled)`
  button {
    height: 32px;
    width: 32px;
    padding: 0 !important;
    border: 1px solid transparent;
    border-radius: 4px;
    &.open,
    &:hover {
      background: ${getCSSVarString(
        BUTTON,
        "skye",
        "background-color"
      )} !important;
      .svg-fill {
        fill:var(--icon-snow-fill,var(--icon-snow,var(--snow)));
      }
      svg {
        transform: none;
      }
    }
    &:focus {
      border-color: var(--button-skye-border-color,var(--button-skye,var(--skye)));
      box-shadow: 0 0 2px var(--button-skye-box-shadow-color,var(--button-skye,var(--skye)));
      outline: none;
    }

    svg {
      opacity: 1;
    }
  }
`;

export const Spacer = styled.div`
  display: block;
  width: 32px;
`;

export const WingsDialog = styled(InfoDialog)`
  position: absolute;
  top: 4px;
  left: -29px;
  margin: 0;
  &:hover {
    svg > path {
      fill:var(--icon-skye-fill,var(--icon-skye,var(--skye)));
    }
  }
  .dialog {
    right: 0;
    left: auto;
    margin-left: 0;
  }
`;
