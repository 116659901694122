import { FC } from "react";

import Icon, { WrappingIconProps } from "../../Icon";

export type ArtyBitsProps = WrappingIconProps & { version?: "v1" | "v2" };

const ArtyBitsIcon: FC<ArtyBitsProps> = ({
  version = "v1",
  ...props
}: ArtyBitsProps) => {
  if (version === "v2") {
    return (
      <Icon
        {...props}
        width={85}
        height={40}
        viewBox="0 0 85 40"
        title="Arty Bits"
      >
        <path
          d="M44 8L45.3506 11.6494L49 13L45.3506 14.3506L44 18L42.6494 14.3506L39 13L42.6494 11.6494L44 8Z"
          fill="var(--icon-arty-bits-dark, #6D144F)"
        />
        <path
          d="M24 18L26.1609 23.8391L32 26L26.1609 28.1609L24 34L21.8391 28.1609L16 26L21.8391 23.8391L24 18Z"
          fill="var(--icon-arty-bits-light, #FFBDBB)"
        />
        <circle
          r="1.5"
          transform="matrix(1 -6.79282e-08 -1.12512e-07 -1 51.5 27.5)"
          fill="var(--icon-arty-bits-light, #FFBDBB)"
        />
        <circle
          r="1.5"
          transform="matrix(1 -6.79282e-08 -1.12512e-07 -1 82.5 18.5)"
          fill="var(--icon-arty-bits-light, #FFBDBB)"
        />
        <circle
          r="1.5"
          transform="matrix(1 -6.79282e-08 -1.12512e-07 -1 2.5 17.5)"
          fill="var(--icon-arty-bits-dark, #6D144F)"
        />
      </Icon>
    );
  }

  return (
    <Icon
      {...props}
      width={76}
      height={40}
      viewBox="0 0 76 40"
      title="Arty Bits"
    >
      <path
        d="M61 19L62.3506 22.6494L66 24L62.3506 25.3506L61 29L59.6494 25.3506L56 24L59.6494 22.6494L61 19Z"
        fill="var(--icon-arty-bits-light, #FFBDBB)"
      />
      <path
        d="M37 10L39.1609 15.8391L45 18L39.1609 20.1609L37 26L34.8391 20.1609L29 18L34.8391 15.8391L37 10Z"
        fill="var(--icon-arty-bits-dark, #6D144F)"
      />
      <circle
        r="1.5"
        transform="matrix(1 -6.79279e-08 -1.12513e-07 -1 73.5 12.5)"
        fill="var(--icon-arty-bits-dark, #6D144F)"
      />
      <circle
        r="1.5"
        transform="matrix(1 -6.79279e-08 -1.12513e-07 -1 18.5 27.5)"
        fill="var(--icon-arty-bits-dark, #6D144F)"
      />
      <circle
        r="1.5"
        transform="matrix(1 -6.79279e-08 -1.12513e-07 -1 2.5 12.5)"
        fill="var(--icon-arty-bits-light, #FFBDBB)"
      />
    </Icon>
  );
};

export default ArtyBitsIcon;
