import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, LINK, getCSSVarString, SECTION } from "Libs/themes";

export const Wrapper = styled.li`
  position: relative;
  .children {
    padding-left: 0;
    margin-left: 0;
  }
  &.with-children {
    .environment-link {
      padding-left: 1em;
    }
    &.is-root > .link-wrapper > .link {
      ${semiBoldAlias};
    }
  }
  &.inactive > .link-wrapper > .link {
    color:var(--link-ebony-light-color,var(--link-ebony-light,var(--ebony-light)));
  }
  &.active > .link-wrapper .link,
  .link-wrapper .link:active {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    margin: 0;
    position: relative;
    span.active {
      position: absolute;
      top: 14px;
      left: 4px;
      display: ${props =>
        props.theme.name === "contrast" ? "inline-block" : "none"};
      height: 4px;
      width: 4px;
      border-radius: 4px;
      background-color:var(--link-skye-background-color,var(--link-skye,var(--skye)));
    }
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;

  .link {
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    line-height: 32px;
    font-size: 14px;
    min-width: 150px;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 16px;
    &:hover {
      background-color:var(--link-skye-light-background-color,var(--link-skye-light,var(--skye-light)));
      border-radius: 4px;
      outline: none;
    }
    &:focus {
      outline: none;
      border-radius: 4px;
      border: 1px solid var(--section-skye-dark-border-color,var(--section-skye-dark,var(--skye-dark)));
      box-shadow: 0 0 2px
        var(--section-skye-dark-box-shadow-color,var(--section-skye-dark,var(--skye-dark)));
      margin: -1px;
    }
    .blue {
      color:var(--link-skye-color,var(--link-skye,var(--skye)));
      background: transparent;
    }
  }
  .search-result {
    > span {
      margin: 0;
    }
  }
`;
