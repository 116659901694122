import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { DOCS_STATUS_CHECK_URL } from "Constants/documentationUrls";
import VendorIcon from "Components/VendorIcon";

import PlatformWordmark from "./icons/PlatformWordmark";
import backgroundLarge from "Images/404-lg.png";
import backgroundSmall from "Images/404-sm.png";

const ErrorMessagePageWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;

  ${() => {
    if (process.env.ENABLE_UNBRANDED_ILLUSTRATIONS) {
      return `
        background-color: #eae5e7;
      `;
    } else {
      return `color: #1a192b;
        background-image: url(${backgroundSmall});
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;
        background-color: #eae5e7;
        
        @media screen and (min-width: 900px) {
          background-image: url(${backgroundLarge});
          background-size: cover;
        }`;
    }
  }};
`;

const HeaderWrapper = styled.div.attrs(() => ({
  className: "header-wrapper"
}))`
  margin-top: 32px;
  margin-left: 24px;

  svg {
    height: 38px;
    width: auto;
  }

  @media only screen and (min-width: 900px) {
    margin-left: 156px;
  }
`;

const TextWrapper = styled.div.attrs(() => ({
  className: "text-wrapper"
}))`
  margin-top: 54px;
  margin-left: 24px;
  margin-right: 24px;
  max-height: 60vh;
  z-index: 2;
  position: relative;
  max-width: 662px;

  .code {
    margin: 32px 0 16px;
    overflow: hidden;

    pre {
      font-family: AndaleMono;
      font-size: 12px;
      line-height: 14px;
      background: #302f45;
      max-height: 354px;
      color: #ffffff;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      white-space: pre;
      margin: 0;
      padding: 8px 16px;
      text-align: left;
      border-radius: 2px;
      overflow: scroll;
    }
  }

  .actions {
    margin-top: 48px;
    display: flex;
    align-items: center;

    a.button {
      background-color: rgb(22, 100, 255);
      color: rgb(255, 255, 255);
      border-radius: 2px;
    }

    button,
    a {
      margin-right: 16px;
      margin-top: 0;
      font-size: 15px;
      text-decoration: none;
    }

    .outline {
      color: #4a495e;
    }
  }

  @media only screen and (min-width: 900px) {
    margin-top: 20vh;
    margin-left: 156px;
    max-height: 80vh;

    pre {
      max-height: 120px;
    }

    .actions {
      margin-top: 64px;
    }
  }
`;

export const CodeWrapper = styled.pre`
  padding: 32px;
  max-width: 100%;
  overflow: hidden;
`;

const Heading = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #0e0d34;
  margin-bottom: 24px;
  margin-top: 0;

  @media only screen and (min-width: 900px) {
    font-size: 32px;
    line-height: 22px;
    color: #1a192b;
    margin-bottom: 32px;
  }
`;

const Message = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #4a495e;

  @media only screen and (min-width: 900px) {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #1a192b;
  }
`;

const FullPageError = ({ children, title, message, errorCode, ...props }) => {
  let vendorLogo;
  if (process.env.VENDOR_URL) {
    vendorLogo = require("Vendor_resources/images/logo-email.png"); // eslint-disable-line import/extensions
  }

  return (
    <ErrorMessagePageWrapper {...props}>
      <HeaderWrapper>
        <Link className="logo" to={"/"}>
          {process.env.VENDOR_URL ? (
            <VendorIcon
              width={process.env.CUSTOM_LOGO_WIDTH}
              path={vendorLogo}
            />
          ) : (
            <PlatformWordmark alt="Platform.sh" color="#1a192b" size={122} />
          )}
        </Link>
      </HeaderWrapper>
      <TextWrapper>
        <Heading>{title}</Heading>
        {message && <Message>{message}</Message>}
        {errorCode && <CodeWrapper>{errorCode}</CodeWrapper>}
        {children}
        <p>
          {DOCS_STATUS_CHECK_URL && (
            <FormattedMessage
              id="error_status"
              values={{
                // eslint-disable-next-line react/display-name
                a: (...chunks) => (
                  <a href={DOCS_STATUS_CHECK_URL} target="_blank">
                    {chunks}
                  </a>
                )
              }}
            />
          )}
        </p>
      </TextWrapper>
    </ErrorMessagePageWrapper>
  );
};
FullPageError.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  errorCode: PropTypes.object
};

export default FullPageError;
