import styled, { css } from "styled-components";

import { Props, Variant } from "./Sticker";

const __dash = (color: string, direction: "bottom" | "right") => `
    repeating-linear-gradient(
      to ${direction},
      ${color} 0%,
      ${color} 35%,
      transparent 35%,
      transparent 55%,
      ${color} 55%,
      ${color} 75%,
      transparent 75%,
      transparent 100%
    )
  `;

const getCustomBorder = (color: string) => css`
  background-image: ${__dash(color, "right")}, ${__dash(color, "right")},
    ${__dash(color, "bottom")}, ${__dash(color, "bottom")};
`;

type LayoutProps = Required<Pick<Props, "variant" | "priority">>;

const __overflowEllipsis = css`
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const __short = css`
  ${__overflowEllipsis};
`;

const __button = css`
  display: inline-flex;
  max-width: 100%;
`;

export const Layout = styled.div<LayoutProps>`
  display: inline-block;
  font-family: Open Sans, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  border-radius: 4px;
  background: var(--sticker-${({ priority }) => priority}-background);
  ${({ priority }) => getCustomBorder(`var(--sticker-${priority}-border)`)};
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 24px 1px, 24px 1px, 1px 24px, 1px 24px;
  color: var(--sticker-foreground);

  ${({ variant }) => {
    switch (variant) {
      case Variant.short:
        return __short;
      case Variant.button:
        return __button;
      case Variant.long:
      default:
        return;
    }
  }}

  b {
    font-weight: 600;
  }
`;

export const OverflowEllipsis = styled.div`
  ${__overflowEllipsis};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.div`
  ${__short};
  display: flex;
  min-width: 0;
  max-width: 100%;
  flex: 1;
  align-items: center;
  padding-right: 32px;
`;
