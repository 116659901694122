import React from "react";
import styled, { css as styledCSS } from "styled-components";
import { Link as LinkRouter } from "react-router-dom";

import { css, LINK, getCSSVarString } from "Libs/themes";

export const Wrapper = styled.li`
  padding: 0;
  display: inline-flex;
  align-items: center;
  position: relative;

  ${props =>
    props.separator &&
    styledCSS`
      padding-right: 10px;
    `};

  ${props =>
    props.separator === "/" &&
    styledCSS`
      &:after {
        content: "/";
        position: absolute;
        color: #c9d0e4;
        right: -5px;
        line-height: 22px;
        padding: 6px 8px;
        font-size: 12px;
      }
    `};

  ${props =>
    props.separator === "chevron" &&
    styledCSS`
      &:after {
        content: "";
        position: absolute;
        right: 3px;
        border-color: #c9d0e4;
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-radius: 2px;
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
      }
    `};
`;

// eslint-disable-next-line no-unused-vars
export const Link = styled(({ isActive, ...props }) => (
  <LinkRouter {...props} />
))`
  color: ${props =>
    props.isActive
      ?"var(--link-snow-color,var(--link-snow,var(--snow)))":"var(--link-periwinkle-grey-color,var(--link-periwinkle-grey,var(--periwinkle-grey)))"} !important;
  line-height: 22px;
  padding: 6px 8px;
  font-size: 12px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:focus,
  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
    color:var(--link-snow-color,var(--link-snow,var(--snow)));
    outline: none;
  }

  ${props =>
    props.theme.name === "contrast" &&
    css`
      &:focus {
        background-color: transparent;
        box-shadow: 0 0 0 1px #4786ff;
      }

      &:hover {
        background-color: #98a0ab;
        color: #1a192b;
      }
    `};
`;
