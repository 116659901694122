import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Node } from "../";

const Nodes = ({
  nodes,
  organizationId,
  showEnvs,
  titleFilter,
  visibleNodes
}) => {
  const list = useMemo(
    () =>
      nodes
        .sort((a, b) =>
          a.title.toLowerCase().localeCompare(b.title.toLowerCase())
        )
        .filter(node => visibleNodes.includes(node.environmentId)),
    [nodes, visibleNodes]
  );

  if (!list.length) return null;

  return (
    <ol>
      {list.map(node => (
        <Node
          key={`node-${node.environmentId}`}
          node={node}
          organizationId={organizationId}
          showEnvs={showEnvs}
          titleFilter={titleFilter}
        />
      ))}
    </ol>
  );
};

Nodes.propTypes = {
  nodes: PropTypes.array,
  organizationId: PropTypes.string,
  showEnvs: PropTypes.object,
  titleFilter: PropTypes.string,
  visibleNodes: PropTypes.array
};

export default Nodes;
