import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomScroll from "react-custom-scroll";
import { LiveMessage } from "react-aria-live";
import { css, getCSSVarString, INPUT, SECTION, MENU, LINK } from "Libs/themes";

import SearchInput from "Components/SearchInput";
import MenuDropdownWrapper from "Components/MenuDropdownWrapper";
import Highlighter from "react-highlight-words";

const MenuLayout = styled.div`
  padding: 0;
  .menu-item {
    display: block;
    width: auto;
  }
  .rcs-custom-scroll {
    .rcs-custom-scrollbar {
      opacity: 1;
    }
    .rcs-inner-container {
      padding: 8px 0;
      box-sizing: border-box;
      > div {
        padding: 0 8px;
      }
    }
    .rcs-inner-handle {
      background-color:var(--section-dark-tint-background-color,var(--section-dark-tint,var(--dark-tint)));
      width: 4px;
      border-radius: 4px;
      opacity: 1;
    }
  }
  .project-link {
    display: inline;
    span {
      display: inline;
    }
  }
  .blue {
    color:var(--menu-skye-color,var(--menu-skye,var(--skye)));
    background: transparent;
  }
  a {
    text-decoration: none;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    position: relative;
    span.active {
      position: absolute;
      top: 14px;
      left: 4px;
      display: ${props =>
        props.theme.name === "contrast" ? "inline-block" : "none"};
      height: 4px;
      width: 4px;
      border-radius: 4px;
      background-color:var(--link-skye-background-color,var(--link-skye,var(--skye)));
    }
  }
`;

const Empty = styled.div`
  color:var(--section-night-color,var(--section-night,var(--night)));
  line-height: 32px;
  padding: 0 16px;
  font-size: 14px;
`;

class SimpleMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleFilter: ""
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.listFilter = this.listFilter.bind(this);
  }

  componentDidMount() {
    this.awayListener = e => {
      if (e.cancelBubble) {
        return false;
      }
      this.setState({
        titleFilter: ""
      });
    };

    document.addEventListener("click", this.awayListener);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.awayListener);
  }

  onSearchChange(event) {
    this.setState({
      titleFilter: event.target.value
    });
  }

  listFilter(list) {
    const title = list.title ? list.title : "";

    if (!title) {
      return true;
    }

    return title.toUpperCase().includes(this.state.titleFilter.toUpperCase());
  }

  render() {
    const {
      objects = [],
      isOpen,
      onClick,
      width,
      prefixId,
      className,
      toggleMenu,
      project,
      organization
    } = this.props;

    let listCount = objects ? objects.filter(this.listFilter).count() : 0;

    return (
      <MenuDropdownWrapper
        className={className ? className : ""}
        isOpen={isOpen}
        width={width}
        onClick={e => e.stopPropagation()}
        onKeyUp={e => e.keyCode === 27 && isOpen && toggleMenu()}
      >
        <SearchInput
          inputColor={"var(--input-grey-light-color,var(--input-grey-light,var(--grey-light)))"}
          iconSize={14}
          placeholder="Search projects"
          onChange={this.onSearchChange}
          value={this.state.titleFilter}
          className="search"
          id={prefixId}
        />
        <MenuLayout
          className="dropdown-links"
          role="menu"
          aria-label="projects"
        >
          {this.state.titleFilter && (
            <LiveMessage
              message={`project menu updated to show results that contain ${this.state.titleFilter}. ${listCount} results found.`}
              aria-live="polite"
            />
          )}
          {listCount > 10 ? (
            <CustomScroll heightRelativeToParent="332px">
              <ul>
                {objects.filter(this.listFilter).map(object => (
                  <li key={object.id}>
                    <Link
                      id={`${prefixId}-${object.id}`}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        onClick(e, object.id);
                        toggleMenu();
                      }}
                      className={`menu-item${
                        object.id === project ? " active" : ""
                      }`}
                      tabIndex="0"
                      to={`/${organization}/${object.id}`}
                      role="menuitem"
                      aria-label={object.name || object.title}
                    >
                      {object.id === project && <span className="active" />}
                      <Highlighter
                        highlightClassName="blue"
                        searchWords={[this.state.titleFilter]}
                        textToHighlight={
                          object.name || object.title
                            ? object.name || object.title
                            : "Project Title"
                        }
                        autoEscape={true}
                        activeIndex={-1}
                        caseSensitive={false}
                        className="project-link"
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </CustomScroll>
          ) : listCount === 0 ? (
            <Empty>No results.</Empty>
          ) : (
            <div className="no-scroll">
              <ul>
                {objects.filter(this.listFilter).map(object => (
                  <li key={object.id}>
                    <Link
                      id={`${prefixId}-${object.id}`}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        onClick(e, object.id);
                        toggleMenu();
                      }}
                      className={`menu-item${
                        object.id === project ? " active" : ""
                      }`}
                      tabIndex="0"
                      to={`/${organization}/${object.id}`}
                      role="menuitem"
                      aria-label={object.name || object.title}
                    >
                      {object.id === project && <span className="active" />}
                      <Highlighter
                        highlightClassName="blue"
                        searchWords={[this.state.titleFilter]}
                        textToHighlight={
                          object.name || object.title
                            ? object.name || object.title
                            : "Project Title"
                        }
                        autoEscape={true}
                        activeIndex={-1}
                        caseSensitive={false}
                        className="project-link"
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </MenuLayout>
      </MenuDropdownWrapper>
    );
  }
}

SimpleMenu.propTypes = {
  objects: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  onSearchChange: PropTypes.func,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  width: PropTypes.string,
  searchValue: PropTypes.string,
  prefixId: PropTypes.string,
  className: PropTypes.string,
  toggleMenu: PropTypes.func,
  project: PropTypes.string,
  organization: PropTypes.string
};

export default SimpleMenu;
