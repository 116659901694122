import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import CustomScroll from "react-custom-scroll";

import ChevronIcon from "Icons/ChevronIcon";

import * as S from "./List.styles";

const List = ({ environments = [], sort, titleFilter, toggleSort }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.SortWrapper>
        {["title", "type", "created_at", "deployed_at"].map(elt => (
          <S.SortBtn
            key={`sort-${elt}`}
            onClick={e => toggleSort(e, elt)}
            active={sort.type === elt}
            dir={sort.type === elt ? sort.order : "asc"}
            tabIndex="0"
            kind={elt}
          >
            <S.Label>
              {intl.formatMessage({
                id: `environmentTree.sort.${elt}`
              })}
            </S.Label>
            <ChevronIcon />
          </S.SortBtn>
        ))}

        <S.Label>
          {intl.formatMessage({ id: "environmentTree.sort.url" })}
        </S.Label>
      </S.SortWrapper>

      <CustomScroll
        {...(environments.length > 12 && {
          heightRelativeToParent: "706px"
        })}
        allowOuterScroll
        onScroll={() => document.dispatchEvent(new Event("scrolled"))}
      >
        {environments.map(node => (
          <S.EnvironmentListBar
            key={`env-${node.title}`}
            displayMode="list"
            node={node}
            titleFilter={titleFilter}
          />
        ))}
      </CustomScroll>
    </S.Wrapper>
  );
};

List.propTypes = {
  environments: PropTypes.array.isRequired,
  sort: PropTypes.object.isRequired,
  titleFilter: PropTypes.string,
  toggleSort: PropTypes.func.isRequired
};

export default List;
