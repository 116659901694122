import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, HEADER } from "Libs/themes";

export default styled.h4`
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  ${semiBoldAlias};
`;
