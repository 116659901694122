import { createIntl, createIntlCache } from "react-intl";

let intl;

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

export const initIntl = (language, messages) => {
  intl = createIntl(
    {
      locale: language,
      messages: messages,
      wrapRichTextChunksInFragment: true
    },
    cache
  );
};

export const getIntl = () => intl;
