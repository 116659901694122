import { FC, ReactNode, memo, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import { WarningIcon, ArtyBitsIcon } from "../Icons";
import { debounce } from "../utils";
import Tooltip from "../Tooltip";

import * as S from "./Banner.styles";

export enum Priority {
  critical = "critical",
  high = "high",
  information = "information"
}

export type Props = {
  priority?: keyof typeof Priority;
  children: ReactNode;
};

const Banner: FC<Props> = ({ children, priority = Priority.information }) => {
  const [textContainer, setTextContainer] = useState<HTMLDivElement>();
  const [overflows, setOverflows] = useState<boolean>(false);

  const calculateOverflows = useCallback((node: HTMLDivElement) => {
    if (node) {
      setTextContainer(node);
      setOverflows(node.scrollWidth > node.offsetWidth);
    }
  }, []);

  useEffect(() => {
    const onResize = debounce(() => {
      if (textContainer) {
        setOverflows(textContainer.scrollWidth > textContainer.offsetWidth);
      }
    });
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [textContainer]);

  return (
    <Tooltip label={overflows ? textContainer?.innerText || "" : ""}>
      <S.Layout priority={priority}>
        {priority === Priority.information ? <ArtyBitsIcon /> : <WarningIcon />}
        <S.TextContainer ref={calculateOverflows}>{children}</S.TextContainer>
        {priority === Priority.information && <ArtyBitsIcon version="v2" />}
      </S.Layout>
    </Tooltip>
  );
};

Banner.propTypes = {
  priority: PropTypes.oneOf(Object.values(Priority)),
  children: PropTypes.node.isRequired
};

export default memo(Banner);
