import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Map } from "immutable";
import { entities } from "Libs/platform";

export const loadSourceOperations = createAsyncThunk(
  "app/project/environment/sourceOps/load",
  async ({ organizationId, projectId, environmentId }) => {
    const sourceOperations = await entities.SourceOperation.query({
      projectId,
      environmentId
    });
    return { sourceOperations, projectId, organizationId, environmentId };
  }
);

export const runOperation = createAsyncThunk(
  "app/project/environment/sourceOps/run",
  async ({ sourceOperation, variables, then }) => {
    sourceOperation.run(variables).then(then);
  }
);

const sourceOperations = createSlice({
  name: "sourceOperations",
  initialState: Map({ data: Map() }),
  extraReducers: {
    [runOperation.pending]: state => state,
    [runOperation.fulfilled]: state => state,
    [runOperation.rejected]: state => state,
    [loadSourceOperations.pending]: state => state.set("loading", true),
    [loadSourceOperations.fulfilled]: (state, { payload }) => {
      const { sourceOperations, projectId, organizationId, environmentId } =
        payload;

      return state
        .setIn(
          ["data", organizationId, projectId, environmentId],
          sourceOperations
        )
        .set("loading", false);
    },
    [loadSourceOperations.rejected]: state => state.set("loading", false)
  }
});

export const sourceOperationSelector = (
  state,
  { organizationId, projectId, environmentId }
) =>
  state.sourceOperations?.getIn([
    "data",
    organizationId,
    projectId,
    environmentId
  ]);

export default sourceOperations.reducer;
