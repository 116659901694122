import { FC, SVGAttributes, ReactNode } from "react";
import PropTypes from "prop-types";

import * as S from "./Icon.styles";

const DEFAULT_ICON_SIZE = 24;

export type IconProps = SVGAttributes<SVGSVGElement> & {
  title?: string;
  children: ReactNode;
};

export type WrappingIconProps = Omit<IconProps, "children">;

const Icon: FC<IconProps> = ({
  title,
  children,
  role = "img",
  width = DEFAULT_ICON_SIZE,
  height = DEFAULT_ICON_SIZE,
  ...props
}) => (
  <S.Layout
    viewBox="0 0 24 24"
    strokeWidth={0}
    width={width}
    height={height}
    aria-hidden={!title}
    role={role}
    aria-labelledby={title && title + "-icon"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title && <title>{title}</title>}
    {children}
  </S.Layout>
);

Icon.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  role: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Icon;
