import { FC } from "react";
import Icon, { WrappingIconProps } from "../../Icon";

const LinkIcon: FC<WrappingIconProps> = (props: WrappingIconProps) => (
  <Icon {...props} title="Link">
    <path d="M13.06 15.94C11.72 15.94 10.47 15.42 9.52001 14.48C9.13001 14.09 9.13001 13.46 9.52001 13.07C9.91001 12.68 10.54 12.68 10.93 13.07C12.06 14.2 14.04 14.2 15.17 13.07L18 10.24C19.17 9.06999 19.17 7.16999 18 5.99999C16.83 4.82999 14.93 4.82999 13.76 5.99999L13.06 6.69999C12.67 7.08999 12.04 7.08999 11.65 6.69999C11.26 6.30999 11.26 5.67999 11.65 5.28999L12.36 4.57999C14.31 2.62999 17.48 2.62999 19.43 4.57999C21.38 6.52999 21.38 9.69999 19.43 11.65L16.6 14.48C15.65 15.42 14.4 15.94 13.06 15.94Z" />
    <path d="M8.10999 20.89C6.82999 20.89 5.54999 20.4 4.56999 19.43C3.62999 18.49 3.10999 17.23 3.10999 15.89C3.10999 14.55 3.62999 13.3 4.56999 12.35L7.39999 9.53C9.34999 7.58 12.52 7.58 14.47 9.53C14.86 9.92 14.86 10.55 14.47 10.94C14.08 11.33 13.45 11.33 13.06 10.94C11.93 9.81 9.94999 9.81 8.81999 10.94L5.98999 13.77C5.41999 14.34 5.10999 15.09 5.10999 15.89C5.10999 16.69 5.41999 17.45 5.98999 18.01C7.15999 19.18 9.05999 19.18 10.23 18.01L10.94 17.3C11.33 16.91 11.96 16.91 12.35 17.3C12.74 17.69 12.74 18.32 12.35 18.71L11.64 19.42C10.67 20.4 9.38999 20.89 8.10999 20.89Z" />
  </Icon>
);

export default LinkIcon;
