export const setIsLoadingState = (state, namespace, isLoading = true) =>
  namespace ? state.setIn(["inflight", namespace].flat(), isLoading) : state;

export const getIsLoadingState = (state, namespace) => {
  return state?.getIn(["inflight", namespace].flat());
};

export const setErrorState = (state, namespace, error = null) =>
  namespace ? state.setIn(["_errors", namespace].flat(), error) : state;

export const getErrorState = (state, namespace) =>
  state?.getIn(["_errors", namespace].flat());

export default getIsLoadingState;
