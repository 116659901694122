import { FC } from "react";
import Icon, { WrappingIconProps } from "../../Icon";

const HelpIcon: FC<WrappingIconProps> = (props: WrappingIconProps) => (
  <Icon {...props} title="Help">
    <path d="M15.2391 7.54348C15.9348 8.32609 16.2609 9.3913 16.1087 10.5217C15.8478 12.6087 14.3261 13.3478 12.9783 13.3478C12.913 13.3478 12.8696 13.3478 12.8696 13.3478V13.8696C12.8696 14.3478 12.4783 14.7391 12 14.7391C11.5217 14.7391 11.1304 14.3478 11.1304 13.8696V13.1739C11.1304 12.4565 11.4348 11.6087 12.9783 11.6087C13.8261 11.6087 14.2609 11.1739 14.3696 10.3043C14.413 10.0217 14.4348 9.26087 13.913 8.69565C13.5 8.23913 12.8261 8 11.913 8C9.95652 8 9.8913 9.28261 9.8913 9.41304C9.8913 9.8913 9.5 10.2826 9.02174 10.2826C8.54348 10.2826 8.15217 9.8913 8.15217 9.41304C8.15217 8.54348 8.82609 6.26087 11.913 6.26087C13.7174 6.26087 14.7174 6.95652 15.2391 7.54348ZM11.9348 15.4783C11.6522 15.4783 11.3696 15.587 11.1739 15.8043C10.9783 16 10.8478 16.2826 10.8478 16.5652C10.8478 16.8478 10.9565 17.1304 11.1739 17.3261C11.3696 17.5217 11.6522 17.6522 11.9348 17.6522C12.2174 17.6522 12.5 17.5435 12.6957 17.3261C12.8913 17.1304 13.0217 16.8478 13.0217 16.5652C13.0217 16.2826 12.913 16 12.6957 15.8043C12.5 15.6087 12.2391 15.4783 11.9348 15.4783ZM22 12C22 17.5217 17.5217 22 12 22C6.47826 22 2 17.5217 2 12C2 6.47826 6.47826 2 12 2C17.5217 2 22 6.47826 22 12ZM20.2609 12C20.2609 7.43478 16.5652 3.73913 12 3.73913C7.43478 3.73913 3.73913 7.43478 3.73913 12C3.73913 16.5652 7.43478 20.2609 12 20.2609C16.5652 20.2609 20.2609 16.5652 20.2609 12Z" />
  </Icon>
);

export default HelpIcon;
