import styled from "styled-components";
import { CloseButton as BaseCloseButton } from "ds/Button";
import { getFontColor } from "../Wrapper/styles";

export const CloseButton = styled(BaseCloseButton)`
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  right: 8px;
  position: absolute;
  top: 4px;
  cursor: pointer;

  svg [fill] {
    fill: ${props => getFontColor(props.type, props.theme)};
  }
`;
