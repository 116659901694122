import styled from "styled-components";
import { css, LINK } from "Libs/themes";

const Link = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color:var(--link-skye-color,var(--link-skye,var(--skye)));
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export default Link;
