import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getOrganizationDescriptionIdFromProject } from "Libs/utils";

import { NavBarLink } from "./styles";
import AnimatedHeaderWithArrow from "Components/AnimatedHeaderWithArrow";
import Label from "Components/fields/Label";
import SimpleMenu from "./SimpleMenu";
import ActionDropdown from "Components/ActionDropdown";
import { Menu as EnvironmentTree } from "Components/EnvironmentTree";

import { organizationsSelector } from "Reducers/organization";

const NavigationMenu = ({
  pushAction,
  organizationId,
  projectId,
  environmentId,
  project,
  environment,
  projects
}) => {
  const [isProjectOpen, setProjectMenuState] = useState(false);
  const [isEnvironmentOpen, setEnvironmentMenuState] = useState(false);

  const organizations = useSelector(organizationsSelector);

  const toggleProjectMenu = () => {
    setProjectMenuState(!isProjectOpen);
  };

  const closeProjectMenu = () => {
    setProjectMenuState(false);
  };

  const toggleEnvironmentMenu = () => {
    setEnvironmentMenuState(!isEnvironmentOpen);
  };

  const closeEnvironmentMenu = () => {
    setEnvironmentMenuState(false);
  };

  const focusById = id => {
    setTimeout(() => {
      const focusElement = document.getElementById(id);
      focusElement && focusElement.focus();
    }, 100);
  };

  return (
    <React.Fragment>
      <div className="navigation-menu">
        <NavBarLink
          aria-label="Primary menu"
          className={!environmentId ? "active" : ""}
        >
          <AnimatedHeaderWithArrow
            prefixId="navbar-projects"
            isOpen={isProjectOpen}
            onHeaderClick={() =>
              pushAction(`/:organizationDescriptionId/${projectId}`, {
                organizationDescriptionId:
                  getOrganizationDescriptionIdFromProject(
                    projects.get(projectId, {}),
                    organizations?.toJS()
                  ),
                projectId
              })
            }
            goTo={`/${organizationId}/${projectId}`}
            arrowClick={true}
            onArrowClick={() => {
              toggleProjectMenu();
              focusById("navbar-projects");
            }}
            animate={false}
            ariaLinkLabel="All projects"
            ariaToggleLabel="Open projects"
            className="navbar-link"
            headerPadding="0 8px"
            closeDropDown={closeProjectMenu}
          >
            <Label>
              <FormattedMessage id="project" />
            </Label>
            <span className="text">
              <span className="active" />
              {project && project.title && project.title}
            </span>
          </AnimatedHeaderWithArrow>
          <SimpleMenu
            prefixId="navbar-projects"
            className="navbar-dropdown"
            isOpen={isProjectOpen}
            onClick={(e, projectId) => {
              e.stopPropagation();
              pushAction(`/:organizationDescriptionId/:projectId`, {
                organizationDescriptionId:
                  getOrganizationDescriptionIdFromProject(
                    projects.get(projectId, {}),
                    organizations?.toJS()
                  ),
                projectId
              });
            }}
            onKeyUp={e =>
              e.keyCode === 27 && isProjectOpen && toggleProjectMenu()
            }
            width="200px"
            objects={projects.valueSeq()}
            toggleMenu={toggleProjectMenu}
            aria-label="Projects"
            project={projectId}
            organization={getOrganizationDescriptionIdFromProject(
              projects.get(projectId, {}),
              organizations?.toJS()
            )}
          />
        </NavBarLink>
        <ActionDropdown
          label={
            environment && environment.title
              ? environment.title
              : "Select environment"
          }
          extraLabel="Environment"
          className={`environments-select${environmentId ? " active" : ""}`}
          id="environment-menu"
          openFuction={focusById("environment-search")}
          isOpen={isEnvironmentOpen}
          toggleDropDown={toggleEnvironmentMenu}
          closeDropDown={closeEnvironmentMenu}
        >
          <EnvironmentTree closeDropDown={closeEnvironmentMenu} />
        </ActionDropdown>
      </div>
    </React.Fragment>
  );
};

NavigationMenu.propTypes = {
  project: PropTypes.object,
  subscription: PropTypes.object,
  environment: PropTypes.object,
  projects: PropTypes.object,
  pushAction: PropTypes.func,
  environmentId: PropTypes.string,
  projectId: PropTypes.string,
  organizationId: PropTypes.string
};

export default injectIntl(NavigationMenu);
