import React, { FC, ReactNode, useMemo } from "react";
import PropTypes from "prop-types";

import * as S from "./Sticker.styles";

export enum Variant {
  short = "short",
  long = "long",
  button = "button"
}

export enum Priority {
  critical = "critical",
  high = "high",
  normal = "normal",
  low = "low",
  information = "information"
}

export type Props = {
  variant?: keyof typeof Variant;
  priority?: keyof typeof Priority;
  children: ReactNode;
};

const Sticker: FC<Props> = ({
  variant = Variant.short,
  priority = Priority.information,
  children,
  ...props
}) => {
  const content = useMemo(() => {
    if (variant === "button" && children instanceof Array) {
      const text = children?.slice(0, -1);
      const button = children?.slice(-1);
      return (
        <>
          <S.TextContainer>
            <S.OverflowEllipsis>{text}</S.OverflowEllipsis>
          </S.TextContainer>
          <S.ButtonContainer>{button}</S.ButtonContainer>
        </>
      );
    }
    return children;
  }, [children]);

  return (
    <S.Layout variant={variant} priority={priority} {...props}>
      {content}
    </S.Layout>
  );
};

Sticker.propTypes = {
  variant: PropTypes.oneOf(Object.values(Variant)),
  priority: PropTypes.oneOf(Object.values(Priority)),
  children: PropTypes.node.isRequired
};

export default React.memo(Sticker);
