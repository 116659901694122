import styled, { css } from "styled-components";

export type Props = {
  /** Horizontal alignment of the buttons. Defaults to `start` */
  justifyContent?: "start" | "end";
  /**
   * Whether or not the wrapper should add spacing at the top. This property is
   * here to solve the spacing on the modals. After refactoring those we should
   * remove this property.
   */
  hasSpacing?: boolean;
};

/**
 * The `<ButtonWrappper /> is designed to wrap buttons and apply the correct
 * spacing between each one without the need of adding margins or padding around
 * elements.
 */
const ButtonWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-${({ justifyContent }) => justifyContent || "start"};
  gap: 8px;

  ${({ hasSpacing }) =>
    hasSpacing &&
    css`
      margin-top: 40px;
    `}
`;

export default ButtonWrapper;
