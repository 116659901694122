import styled, { keyframes, css as styledCSS } from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, getCSSVarString, SECTION, MENU_ITEM, LINK } from "Libs/themes";
import NewProjectButton from "Components/NewProjectButton";

import MenuLink from "../MenuLink";

export const Layout = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const FloatingContainer = styled.div`
  li a,
  #test-logo {
    padding: 0;
  }
  position: fixed;

  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // Just so it's above the project creation modal
  z-index: 10;

  background-color: #1a192b;

  display: flex;
  flex-direction: column;
  animation: 0.25s ${fadeIn} ease-out;
`;

export const RightMenu = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const FloatingLogo = styled.div`
  margin-top: 54px;
  margin-left: 40px;
  margin-bottom: 32px;
`;

export const Breadcrumbs = styled.div`
  padding-left: 32px;
  width: 100%;
  margin-bottom: 48px;

  ol {
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-size: 14px;
  }
`;

export const Link = styled(MenuLink)`
  color: inherit;
  text-decoration: none;
  font-size: 18px;
  line-height: 20px;
  font-weight: ${semiBoldAlias};
  position: relative;

  // && is required to override some styles applied to every anchor within the
  // menu
  &&:hover,
  &&:focus,
  &&:active {
    background-color: transparent;
    color:var(--menu-item-skye-light-color,var(--menu-item-skye-light,var(--skye-light)));
  }

  :focus :after,
  :hover :after {
    ${({ theme }) =>
      theme.name === "contrast" &&
      styledCSS`
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        display: block;
        height: 4px;
        width: 4px;
        border-radius: 4px;
        background-color:var(--menu-item-skye-background-color,var(--menu-item-skye,var(--skye)));
      `};
  }
`;

export const UserMenu = styled.ul`
  padding-left: 40px;
  width: 100%;
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 32px;
  margin-right: 0;
  padding: 0 40px;

  li:not(:last-child) {
    padding-bottom: 16px;
  }

  a.add-link {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;

    :active,
    :focus,
    :hover {
      height: auto;
      border-radius: 2px;
      background-color:var(--link-skye-dark-background-color,var(--link-skye-dark,var(--skye-dark)));
    }
  }
`;

export const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 16px 40px;
  width: 100%;
  margin-top: auto;
`;

export const UserProfile = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 24px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  line-height: 20px;
  padding-right: 16px;
`;

export const LogOut = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OrganizationName = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
`;

export const OrganizationSwitcher = styled.div`
  padding-bottom: 14px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${getCSSVarString(
    SECTION,
    "ebony-light",
    "border-color"
  )};

  .organization_switch_title {
    margin-bottom: 16px;
    font-size: 14px;
    color:var(--section-periwinkle-grey-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  }
  .organization_switch_name_icon {
    transform: rotate(-90deg);
    path {
      fill: white;
    }
  }
`;

export const ProjectButton = styled(NewProjectButton)`
  span.link-button {
    width: 100%;
  }
`;

export const List = styled.li`
  a {
    display: inline-flex;
    width: 100%;
    font-weight: 400;
    font-size: 17px;
  }
  .orgainzation-name {
    font-size: 17px;
    line-height: 20px;
    color: ${props =>
      props.active
        ?"var(--menu-item-skye-color,var(--menu-item-skye,var(--skye)))": "currentColor"};
  }

  .link-button {
    width: 100%;
    #create_project {
      display: flex;
      justify-content: center;
    }
  }
`;

export const OrganizationContentSwitcherContent = styled.div`
  display: flex;
  justify-content: space-between;
`;
