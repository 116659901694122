import styled from "styled-components";
import Button from "./Button";
import { css as _css, BUTTON } from "Libs/themes";

export const Layout = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled(Button)`
  ${_css(BUTTON, "granite", "color")};
  height: 24px;
  width: 24px;

  :hover {
    background-color: transparent;
  }

  > :first-child {
    width: auto;
    height: auto;
  }
`;

export const Text = styled.span`
  margin-right: 16px;
  font-size: 13px;
  line-height: 16px;
  ${_css(BUTTON, "slate", "color")};
  pointer-events: none;
  user-select: none;
`;
