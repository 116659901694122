export const CLI_COMMAND_NAME = process.env.CUSTOM_CLI_COMMAND || "platform";

export const APP_NAME = process.env.CUSTOM_APP_NAME || "Platform.sh";

export const CLI_DOWNLOAD_URL =
  process.env.CUSTOM_CLI_DOWNLOAD_URL || "https://platform.sh/cli/installer";

export const CONFIG_DIR_NAME =
  process.env.CUSTOM_CONFIG_DIR_NAME || ".platform";

export const DOCS_STATUS_CHECK_URL = process.env.CUSTOM_DOCS_STATUS_CHECK_URL;
