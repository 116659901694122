import React, { FC, ReactNode } from "react";
import Normal from "../Icons/Normal";
import Information from "../Icons/Information";
import Low from "../Icons/Low";
import Critical from "../Icons/Critical";

import * as S from "./Sticker.styles";

export enum Variant {
  short = "short",
  long = "long",
  button = "button"
}

export enum Priority {
  critical = "critical",
  normal = "normal",
  low = "low",
  information = "information"
}

export type Props = {
  variant?: keyof typeof Variant;
  priority?: keyof typeof Priority;
  children: ReactNode;
  hasIcon?: boolean;
  text: ReactNode;
  closeIcon?: ReactNode;
  button?: ReactNode;
  link?: ReactNode;
};

const getIcon = (priority: string) => {
  switch (priority) {
    case "low":
      return <Low />;
    case "critical":
      return <Critical />;
    case "information":
      return <Information />;
    case "normal":
      return <Normal />;
  }
};

const Sticker: FC<Props> = ({
  variant = Variant.short,
  priority = Priority.information,
  children,
  text,
  button,
  closeIcon,
  link,
  hasIcon = true,
  ...props
}) => {
  return (
    <S.Layout variant={variant} priority={priority} {...props}>
      {hasIcon && getIcon(priority)}
      <S.TextContainer>
        <S.OverflowEllipsis>
          {text}
          {link}
        </S.OverflowEllipsis>
      </S.TextContainer>
      {(variant === "button" || variant === "long") && (
        <S.ButtonContainer>{button}</S.ButtonContainer>
      )}
      {variant === "long" && <S.ButtonContainer>{closeIcon}</S.ButtonContainer>}
      {children}
    </S.Layout>
  );
};

export default Sticker;
