import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import logger from "Libs/logger";

import { CopyButton, Link } from "ds/Button";
import FullPageError from "../FullPageError";
import { CodeWrapper } from "../FullPageError/FullPageError";

const Buttons = styled.div.attrs(() => ({
  className: "buttons"
}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div.attrs(() => ({
  className: "button-wrapper"
}))`
  margin-top: 48px;

  @media screen and (min-width: 900px) {
    margin-top: 64px;
  }
`;

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {},
      info: {}
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
    const spinner = document.getElementById("pre-loading");
    const fallback = document.getElementById("fallback");
    if (spinner) {
      spinner.remove();
    }
    if (fallback) {
      fallback.remove();
    }

    logger(error);
  }

  render() {
    const {
      hasError,
      error: { stack }
    } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <FullPageError
          title="It's not you - it's us"
          message="The following error broke our application before it could load."
        >
          <div className="code">
            <CodeWrapper>{stack}</CodeWrapper>
          </div>
          <Buttons>
            <CopyButton text={stack} />
            <ButtonWrapper>
              <Link variant="primary" to="/">
                Back to projects
              </Link>
              <Link
                href={
                  process.env.CUSTOM_SUPPORT_URL
                    ? process.env.CUSTOM_SUPPORT_URL
                    : `${process.env.ACCOUNTS_URL}/support`
                }
                target="_blank"
                variant="outline"
              >
                Report
              </Link>
            </ButtonWrapper>
          </Buttons>
        </FullPageError>
      );
    }
    return this.props.children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default AppErrorBoundary;
