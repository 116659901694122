import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { capitalize } from "Libs/utils";
import Button from "ds/Button";
import { LayoutProps, Content } from "./Button.types";
import PlusIcon from "UI/icons/Plus";

export type Props =
  | LayoutProps
  | {
      content: Content.mixed;
    };

const AddButton: FC<Props> = ({ children, ...props }) => (
  <Button {...props} content="mixed">
    <PlusIcon />
    {children ? (
      typeof children === "string" ? (
        capitalize(children)
      ) : (
        children
      )
    ) : (
      <FormattedMessage id="add" />
    )}
  </Button>
);

export default AddButton;
