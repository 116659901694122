import React, { useEffect, useState, MouseEvent, useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import copyToClipboard from "copy-to-clipboard";

import CopyIcon from "Icons/CopyIcon";
import SuccessIcon from "ds/Icons/Success";
import Button from "ds/Button";

import { Variants } from "./Button.types";

export type Props = {
  /** onClick event handler attached to the button */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** Event handler triggered once the text has been copied to the clipboard */
  onCopy?: (text: string) => void;
  /** A custom icon can be provided. It will replace the default copy icon */
  icon?: React.ReactNode;
  /** Content to be copied */
  text: string;
  /** Text to replace the `copy` fragment it will be displayed when the user has not interacted with the copy button */
  title?: React.ReactNode;
  /** Wether to display the copy and copied message within the button or not */
  withText?: boolean;
  /** Button variant */
  variant?: Variants;
};

const CopyButton: React.FC<Props> = ({
  icon = <CopyIcon />,
  text,
  title = <FormattedMessage id="copy" />,
  variant,
  onClick = () => void 0,
  onCopy = copyToClipboard,
  withText = true,
  ...props
}) => {
  const intl = useIntl();

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [copied]);

  const onClickCopy = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (text) {
        onCopy(text);
        setCopied(true);
      }
      const activeElement = document.activeElement as HTMLButtonElement;
      activeElement?.blur();
      onClick(event);
    },
    [text, onCopy, onClick]
  );

  return (
    <Button
      onClick={onClickCopy}
      {...props}
      content={withText ? "mixed" : "icon"}
      variant={variant}
    >
      {copied && <SuccessIcon />}
      {copied ? null : icon}
      {withText && (copied ? intl.formatMessage({ id: "copied" }) : title)}
    </Button>
  );
};

export default CopyButton;
