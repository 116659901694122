import { FC } from "react";
import Icon, { WrappingIconProps } from "../../Icon";

const ScrollTopIcon: FC<WrappingIconProps> = (props: WrappingIconProps) => (
  <Icon {...props} title="ScrollTop">
    <path d="M8.8371 14.6125L11.1246 12.325L11.1246 20.125C11.1246 20.6082 11.5164 21 11.9996 21C12.4829 21 12.8746 20.6082 12.8746 20.125L12.8746 12.325L15.1621 14.6125C15.5004 14.9508 16.0488 14.9508 16.3871 14.6125C16.7254 14.2742 16.7254 13.7258 16.3871 13.3875L12.7067 9.70711C12.3162 9.31658 11.683 9.31658 11.2925 9.70711L7.6121 13.3875C7.27383 13.7258 7.27383 14.2742 7.6121 14.6125C7.95038 14.9508 8.49883 14.9508 8.8371 14.6125ZM11.9996 3C13.1042 3 13.9996 3.89543 13.9996 5C13.9996 6.10457 13.1042 7 11.9996 7C10.895 7 9.9996 6.10457 9.9996 5C9.9996 3.89543 10.895 3 11.9996 3Z" />
  </Icon>
);

export default ScrollTopIcon;
