import styled, { css } from "styled-components";
import { getCSSVarString, TOOLTIP } from "Libs/themes";

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  /*
   * This fills the gap between the tooltip and the container making tooltips
   * hoverable if there is any gap.
   */
  &:hover:before {
    content: "";
    display: block;
    position: absolute;
    height: 10px;
    z-index: 999;
    ${({ tooltipProps }) =>
      tooltipProps.place === "top" &&
      css`
        left: -10px;
        right: -10px;
        top: -10px;
      `};

    ${({ tooltipProps }) =>
      tooltipProps.place === "bottom" &&
      css`
        left: -10px;
        right: -10px;
        bottom: -10px;
      `};

    ${({ tooltipProps }) =>
      tooltipProps.place === "left" &&
      css`
        left: -10px;
        top: -10px;
        bottom: -10px;
      `};

    ${({ tooltipProps }) =>
      tooltipProps.place === "right" &&
      css`
        right: -10px;
        top: -10px;
        bottom: -10px;
      `};
  }

  /*
  * Shift position of tooltip's arrow by passing in className prop
  */
  ${({ tooltipProps }) =>
    tooltipProps.className === "rightArrowDown" &&
    css`
      .__react_component_tooltip.place-top:after {
        left: 92%;
      }
    `};

  ${({ tooltipProps }) =>
    tooltipProps.className === "leftArrowDown" &&
    css`
      .__react_component_tooltip.place-top:after {
        left: 8%;
      }
    `};

  ${({ tooltipProps }) =>
    tooltipProps.className === "leftArrowUp" &&
    css`
      .__react_component_tooltip.place-bottom:after {
        left: 8%;
      }
    `};

  ${({ tooltipProps }) =>
    tooltipProps.className === "rightArrowUp" &&
    css`
      .__react_component_tooltip.place-bottom:after {
        left: 92%;
      }
    `};

  /* react-tooltips can't have pointer events by default */
  [role="tooltip"] {
    pointer-events: auto;
  }

  border: ${({ theme }) =>
    theme.name === "contrast" ? "1px solid transparent" : "none"};

  &:focus {
    ${({ theme }) =>
      theme.name === "contrast" &&
      css`
        box-shadow: 0 0 2px
          var(--tooltip-skye-box-shadow-color,var(--tooltip-skye,var(--skye)));
        border: 1px solid var(--tooltip-skye-border-color,var(--tooltip-skye,var(--skye)));
        outline: none;
      `};
  }
`;
