import React from "react";

import Button, { Props } from "./Button";

/**
 * This component should be used when the click action will bring the user to a
 * new URL.
 *
 * Based on the `to` or `href` properties will render a React Router DOM link or
 * a regular HTML anchor.
 *
 */
const Link: React.FC<Props> = props => (
  <Button {...props} variant={props.variant || "link"} />
);

export default Link;
