import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: center;
  max-width: 270px;

  input:focus {
    border: none;
    box-shadow: none;
  }

  nav {
    top: unset;
    left: unset;
    width: unset;
    min-width: 100px !important;
    margin-top: 30px;
    & > div {
      max-width: 280px;
      min-width: 216px !important;
    }
    span {
      font-size: 15px;
    }
  }

  .header-arrow,
  .chevron-link {
    outline: none;
  }
`;

export const CurrentOrganization = styled.span`
  font-size: 16px;
  ${semiBoldAlias};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
`;
