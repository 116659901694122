import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, LINK, getCSSVarString, ICON, MENU } from "Libs/themes";

export const DIALOG_WIDTH = 300;

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: ${props => props.height};
  width: ${props => props.width};
  display: inline-block;
  margin-right: 12px;
  flex-shrink: 0;
  svg {
    height: auto;
    width: 100%;
    > path {
      fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
    }
  }
  &:hover,
  &:focus {
    outline: none;
    svg > path {
      fill:var(--icon-slate-fill,var(--icon-slate,var(--slate)));
    }
  }
`;

export const DialogContent = styled.div`
  background-color:var(--menu-snow-background-color,var(--menu-snow,var(--snow)));
  border-radius: 2px;
  min-width: fit-content;
  width: ${DIALOG_WIDTH}px;
  box-shadow: var(--menu-box-shadow-box-shadow,var(--menu-box-shadow,var(--box-shadow)))
    var(--menu-ebony-shadow-box-shadow-color,var(--menu-ebony-shadow,var(--ebony-shadow)));
  padding: 16px;
  line-height: 22px;
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  z-index: 11;
  letter-spacing: 0;
  box-sizing: border-box;

  a {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
    text-decoration: none;
    padding: 0 8px;
    margin: 0 -8px -6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: none;
      border: 1px solid var(--link-skye-dark-border-color,var(--link-skye-dark,var(--skye-dark)));
      border-radius: 4px;
      box-shadow: 0 0 2px
        var(--link-skye-dark-box-shadow-color,var(--link-skye-dark,var(--skye-dark)));
      text-decoration: none;
      margin: -1px -9px -7px;
    }
    &:active {
      border: none;
      box-shadow: none;
      text-decoration: underline;
      margin: 0 -8px;
    }
  }
  a + a {
    margin-left: 16px;
    &:focus {
      margin-left: 15px;
    }
    &:active {
      margin-left: 16px;
    }
  }
`;

export const DialogWrapper = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  padding-top: 8px;
  z-index: 100;
`;

export const Title = styled.div`
  margin-bottom: 4px;
  white-space: normal;
  color:var(--menu-ebony-light-color,var(--menu-ebony-light,var(--ebony-light)));
  ${semiBoldAlias};
`;

export const Text = styled.div`
  white-space: normal;
  color:var(--menu-dark-tint-color,var(--menu-dark-tint,var(--dark-tint)));
`;
