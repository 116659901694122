import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { hasHtml } from "Libs/utils";
import { setIsLoadingState } from "Reducers/utils";
import logger from "Libs/logger";

/**
 * Load project's environments
 *
 * @param {string} organizationId
 * @param {string} projectId
 *
 */
export const loadEnvironments = createAsyncThunk(
  "app/project/environments",
  async ({ organizationId, projectId }, { rejectWithValue }) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const environments = await client.getEnvironments(projectId);
      return environments;
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(
          {
            errMessage: err,
            organizationId,
            projectId
          },
          {
            action: "environments_load"
          }
        );
      }
      return rejectWithValue({ errors: err.detail });
    }
  }
);

/**
 * Load a project's environment
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {string} environmentId
 *
 */
export const loadEnvironment = createAsyncThunk(
  "app/project/environment",
  async ({ organizationId, projectId, environmentId }, { rejectWithValue }) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const environment = await client.getEnvironment(
        projectId,
        encodeURIComponent(environmentId)
      );
      return environment;
    } catch (err) {
      let error = err;
      if (error.code === 404) {
        error.message = "environment.notfound";
      } else if (error.code !== 403) {
        logger(
          {
            errMessage: error.message,
            organizationId,
            projectId,
            environmentId
          },
          {
            action: "environment_load"
          }
        );
      }
      return rejectWithValue({ errors: err.detail });
    }
  }
);

/**
 * Update environment
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {string} environmentId
 * @param {object} data
 *
 */
export const updateEnvironment = createAsyncThunk(
  "app/project/environment/update",
  async (
    { organizationId, projectId, environmentId, data },
    { getState, rejectWithValue }
  ) => {
    const environment = environmentSelector(getState(), {
      organizationId,
      projectId,
      environmentId
    });
    if (!environment) return false;

    try {
      const result = await environment.update(data);
      const newEnvironment = await result.getEntity();
      return newEnvironment;
    } catch (err) {
      logger(
        {
          errMessage: err.message,
          environmentId
        },
        {
          action: "environment_update"
        }
      );
      return rejectWithValue({ errors: err.detail });
    }
  }
);

/**
 * Toggle project's environment activation
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {string} environmentId
 *
 */
export const toggleEnvironmentActivation = createAsyncThunk(
  "app/project/environment/activation/toggle",
  async (
    { organizationId, projectId, environmentId },
    { getState, rejectWithValue }
  ) => {
    const environment = environmentSelector(getState(), {
      organizationId,
      projectId,
      environmentId
    });
    if (!environment) return false;

    try {
      const isActive = await environment.isActive();
      let activity = await environment[isActive ? "deactivate" : "activate"]();
      activity = await activity.wait();

      if (activity.result === "failure") {
        return rejectWithValue({ errors: activity.log });
      }
      return activity;
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(
          {
            errMessage: err.message,
            organizationId,
            projectId,
            environmentId
          },
          {
            action: "toggleEnvironmentActivation"
          }
        );
        return rejectWithValue({ errors: err.detail });
      }
    }
  }
);

const environment = createSlice({
  name: "app/project/environment",
  initialState: Map(),
  reducers: {
    deleteEnvironments(state, { payload }) {
      const { organizationId, projectId, environmentIds } = payload;
      return state.withMutations(map => {
        environmentIds.forEach(environmentId => {
          map.deleteIn(["data", organizationId, projectId, environmentId]);
        });
      });
    },
    setEditLine(state, action) {
      return state.set("editedLine", action.payload);
    },
    loadEnvironmentFromEventSuccess(state, { payload }) {
      const { environment, organizationId } = payload;
      return state.setIn(
        ["data", organizationId, environment.project, environment.id],
        fromJS(environment)
      );
    }
  },
  extraReducers: {
    // LOAD LIST
    [loadEnvironments.pending]: (state, { meta }) => {
      const { projectId } = meta.arg;
      return state
        .deleteIn(["errors", "project", projectId])
        .setIn(["loading", "project", projectId], true);
    },
    [loadEnvironments.fulfilled]: (state, { meta, payload }) => {
      const { organizationId, projectId } = meta.arg;
      return state
        .setIn(["loading", "project", projectId], false)
        .setIn(
          ["data", organizationId, projectId],
          fromJS(
            payload.reduce((list, env) => {
              list[env.id] = fromJS(env);
              return list;
            }, {})
          )
        )
        .setIn(["tree", organizationId, projectId], fromJS(getTree(payload)));
    },
    [loadEnvironments.rejected]: (state, { meta, payload }) => {
      const { projectId } = meta.arg;
      return state
        .setIn(["loading", "project", projectId], false)
        .setIn(["errors", "project", projectId], fromJS(payload.errors));
    },

    // LOAD ENV
    [loadEnvironment.pending]: (state, { meta }) => {
      const { organizationId, environmentId, projectId } = meta.arg;
      return setIsLoadingState(
        state,
        [organizationId, projectId, environmentId],
        true
      )
        .deleteIn(["errors", "environment", projectId, environmentId])
        .setIn(["loading", "environment", projectId, environmentId], true);
    },
    [loadEnvironment.fulfilled]: (state, { meta, payload }) => {
      const { environmentId, organizationId, projectId } = meta.arg;
      return setIsLoadingState(
        state,
        [organizationId, projectId, environmentId],
        false
      )
        .setIn(["loading", "environment", projectId, environmentId], false)
        .setIn(
          ["data", organizationId, projectId, environmentId],
          fromJS(payload)
        );
    },
    [loadEnvironment.rejected]: (state, { meta, payload }) => {
      const { organizationId, environmentId, projectId } = meta.arg;
      return setIsLoadingState(
        state,
        [organizationId, projectId, environmentId],
        false
      )
        .setIn(["loading", "environment", projectId, environmentId], false)
        .setIn(
          ["errors", "environment", projectId, environmentId],
          fromJS(payload.errors)
        );
    },

    // UPDATE
    [updateEnvironment.pending]: (state, { meta }) => {
      const { environmentId, projectId } = meta.arg;
      return state
        .deleteIn(["errors", "environmentUpdate", projectId, environmentId])
        .setIn(["loading", "environment", projectId, environmentId], true);
    },
    [updateEnvironment.fulfilled]: (state, { meta, payload }) => {
      const { environmentId, organizationId, projectId } = meta.arg;
      return state
        .setIn(["data", organizationId, projectId, payload.id], fromJS(payload))
        .setIn(["loading", "environment", projectId, environmentId], false);
    },
    [updateEnvironment.rejected]: (state, { meta, payload }) => {
      const { environmentId, projectId } = meta.arg;
      return state
        .setIn(
          ["errors", "environmentUpdate", projectId, environmentId],
          fromJS(payload.errors)
        )
        .setIn(["loading", "environment", projectId, environmentId], false);
    },

    // Toggle activation
    [toggleEnvironmentActivation.pending]: (state, { meta }) => {
      const { environmentId, projectId } = meta.arg;
      return state
        .deleteIn(["errors", "environmentUpdate", projectId, environmentId])
        .setIn(["loading", "toggleActivation", projectId, environmentId], true);
    },
    [toggleEnvironmentActivation.fulfilled]: (state, { meta, payload }) => {
      const { environmentId, projectId } = meta.arg;
      return state
        .setIn(["loading", "toggleActivation", projectId, environmentId], false)
        .setIn(
          ["data", "toggleActivation", projectId, environmentId],
          fromJS(payload)
        );
    },
    [toggleEnvironmentActivation.rejected]: (state, { meta, payload }) => {
      const { environmentId, projectId } = meta.arg;
      return state
        .setIn(["loading", "toggleActivation", projectId, environmentId], false)
        .setIn(
          ["errors", "environmentUpdate", projectId, environmentId],
          fromJS(payload.errors)
        );
    }
  }
});

const getTree = data => {
  let root;

  const tree = data.reduce((acc, el) => {
    acc[el.id] = { id: el.id, parent: el.parent };
    return acc;
  }, {});

  let orphanedEnvironments = [];
  for (const elt of Object.values(tree)) {
    // Handle the root element
    if (elt.parent === null) {
      root = elt;
      continue;
    }

    let parentElt = tree[elt.parent];
    if (parentElt) {
      // Add our current elt to its parent's `children` array
      parentElt.children = [...(parentElt.children || []), elt];
    } else {
      // Add current element to the orphanedEnvironments array instead
      orphanedEnvironments.push(elt);
    }
  }

  for (const env of orphanedEnvironments) {
    env.stub = env.parent;
    env.parent = root.id;
    root.children = [...(root.children || []), env];
  }

  const dfs = (node, parent) => {
    let depth = 0;
    let path = [];
    if (parent) {
      path = tree[parent.id] ? [...parent.path, ...[parent.id]] : [root.id];
      depth = tree[parent.id] ? parent.depth + 1 : 1;
    }
    const current = { id: node.id, depth, path };
    tree[node.id] = current;

    if (!node.children) return;
    node.children?.forEach(child => dfs(child, current));
  };

  dfs(root);
  return tree;
};

export const {
  deleteEnvironments,
  loadEnvironmentFromEventSuccess,
  setEditLine
} = environment.actions;
export default environment.reducer;

const selectEnv = ({ environment }) => environment || Map();
const getParams = (_, params) => params;

export const editedLineSelector = createSelector(selectEnv, environment =>
  environment.get("editedLine")
);

// Tree
export const environmentTreeSelector = createSelector(
  selectEnv,
  getParams,
  (environment, { organizationId, projectId }) =>
    environment.getIn(["tree", organizationId, projectId])
);

// Evnrionment
export const environmentSelector = createSelector(
  selectEnv,
  getParams,
  (environment, { environmentId, organizationId, projectId }) =>
    environment.getIn(["data", organizationId, projectId, environmentId])
);

export const environmentLoadingSelector = createSelector(
  selectEnv,
  getParams,
  (environment, { environmentId, projectId }) =>
    environment.getIn(
      ["loading", "environment", projectId, environmentId],
      false
    )
);

export const environmentErrorSelector = createSelector(
  selectEnv,
  getParams,
  (environment, { environmentId, projectId }) =>
    environment.getIn(["errors", "environment", projectId, environmentId])
);

export const environmentUpdateErrorSelector = createSelector(
  selectEnv,
  getParams,
  (environment, { environmentId, projectId }) =>
    environment.getIn(["errors", "environmentUpdate", projectId, environmentId])
);

export const toggleActivationLoadingSelector = createSelector(
  selectEnv,
  getParams,
  (environment, { environmentId, projectId }) =>
    environment.getIn(
      ["loading", "toggleActivation", projectId, environmentId],
      false
    )
);

// Project's Environments
export const environmentsSelector = createSelector(
  selectEnv,
  getParams,
  (environment, { organizationId, projectId }) =>
    environment.getIn(["data", organizationId, projectId])
);

export const environmentsLoadingSelector = createSelector(
  selectEnv,
  getParams,
  (environment, { projectId }) =>
    environment.getIn(["loading", "project", projectId], false)
);

export const environmentsErrorSelector = createSelector(
  selectEnv,
  getParams,
  (environment, { projectId }) =>
    environment.getIn(["errors", "project", projectId])
);

const simplifyEnvs = env => ({
  id: env[1]._root.entries[0][1],
  depth: env[1]._root.entries[1][1],
  path: env[1]._root.entries[2][1]._tail?.array
});

const array2Tree = (arr = []) => {
  let roots = arr.filter(env => env.depth === 0);

  const getChildren = env => {
    let children = arr.filter(
      elt => elt.depth === env.depth + 1 && elt.path.includes(env.id)
    );

    children.forEach((child, i) => {
      children[i].children = getChildren(child);
    });
    return children;
  };

  roots.forEach((env, i) => {
    roots[i].children = getChildren(env);
  });

  return roots;
};

const cleanEnvsForComponent = arr => {
  return arr.map(e => {
    let cleaned = {
      id: e.id
    };
    if (e.children.length) {
      cleaned.children = cleanEnvsForComponent(e.children);
    }
    return cleaned;
  });
};

export const environmentMenuSelector = (state, props) => {
  const envs = state.environment?.getIn([
    "tree",
    props.organizationId,
    props.projectId
  ]);

  const arr = envs?.entrySeq()?.toJS();

  // remove stuff the component does not need
  const simplified = arr?.map(simplifyEnvs);

  // // Convert flat tree to tree
  const tree = array2Tree(simplified);

  const clean = cleanEnvsForComponent(tree);
  return clean;
};
