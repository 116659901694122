import React from "react";

import useUniqueId from "Libs/useUniqueId";

export const Success: React.FC<{ color?: string }> = ({
  color = "#5f5e70"
}) => {
  const uid = useUniqueId();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`success-icon-${uid}`}
    >
      <title id={`success-state-icon-${uid}`}>Success icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12ZM5.7 11.3C5.3134 11.6866 5.3134 12.3134 5.7 12.7L9.2929 16.2929C9.68342 16.6834 10.3166 16.6834 10.7071 16.2929L18.3 8.7C18.6866 8.3134 18.6866 7.6866 18.3 7.3C17.9134 6.9134 17.2866 6.9134 16.9 7.3L10 14.2L7.1 11.3C6.7134 10.9134 6.0866 10.9134 5.7 11.3Z"
        fill={color}
      />
    </svg>
  );
};

export default Success;
