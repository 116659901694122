import { getCSSVarString, STICKER, BUTTON } from "Libs/themes";
import styled, { css } from "styled-components";

import { Props, Variant } from "./Sticker";

const PRIORITIES = {
  information: {
    color:"var(--sticker-ebony-color,var(--sticker-ebony,var(--ebony)))",
    background:"var(--sticker-skye-light-background-color,var(--sticker-skye-light,var(--skye-light)))",
    border:"var(--sticker-skye-border-color,var(--sticker-skye,var(--skye)))"
  },
  low: {
    color:"var(--sticker-ebony-color,var(--sticker-ebony,var(--ebony)))",
    background:"var(--sticker-cosmic-latte-background-color,var(--sticker-cosmic-latte,var(--cosmic-latte)))",
    border:"var(--sticker-java-border-color,var(--sticker-java,var(--java)))"
  },
  normal: {
    color:"var(--sticker-ebony-color,var(--sticker-ebony,var(--ebony)))",
    background:"var(--sticker-tulip-pedals-background-color,var(--sticker-tulip-pedals,var(--tulip-pedals)))",
    border:"var(--sticker-gold-border-color,var(--sticker-gold,var(--gold)))"
  },
  critical: {
    color:"var(--sticker-ebony-color,var(--sticker-ebony,var(--ebony)))",
    background:"var(--sticker-misty-rose-background-color,var(--sticker-misty-rose,var(--misty-rose)))",
    border:"var(--sticker-red-border-color,var(--sticker-red,var(--red)))"
  }
};

const dash = (color: string, direction: "bottom" | "right") => `
    repeating-linear-gradient(
      to ${direction},
      ${color} 0%,
      ${color} 35%,
      transparent 35%,
      transparent 55%,
      ${color} 55%,
      ${color} 75%,
      transparent 75%,
      transparent 100%
    )
  `;

const getCustomBorder = (color: string) => css`
  background-image: ${dash(color, "right")}, ${dash(color, "right")},
    ${dash(color, "bottom")}, ${dash(color, "bottom")};
`;

type LayoutProps = Required<Pick<Props, "variant" | "priority">>;

const overflowEllipsis = css`
  white-space: nowrap;
  width: 100%;
  display: inline-flex;
  align-items: center;

  & > a {
    margin-left: 4px;
  }
`;

const short = css`
  word-break: break-all;
`;

const button = css`
  display: inline-flex;
  width: 100%;
`;

const long = css`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

export const Layout = styled.div<LayoutProps>`
  display: flex;
  font-family: Open Sans, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  box-sizing: border-box;
  padding: 10px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  background: ${({ priority }) => PRIORITIES[priority].background};
  ${({ priority }) => getCustomBorder(`${PRIORITIES[priority].border}`)};
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 24px 1px, 24px 1px, 1px 24px, 1px 24px;
  color: var(--sticker-ebony-color,var(--sticker-ebony,var(--ebony)));

  & > svg {
    margin-right: 14px;
    width: 20px;
    height: 20px;
    align-self: center;
  }

  ${({ variant }) => {
    switch (variant) {
      case Variant.short:
        return short;
      case Variant.button:
        return button;
      case Variant.long:
        return long;
      default:
        return;
    }
  }}

  b {
    font-weight: 600;
  }
`;

export const OverflowEllipsis = styled.div`
  // ${overflowEllipsis};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;

  & > button {
    background: var(--button-skye-background-color,var(--button-skye,var(--skye)));
    color: var(--button-snow-color,var(--button-snow,var(--snow)));
  }
`;

export const TextContainer = styled.div`
  ${short};
  display: flex;
  min-width: 0;
  max-width: 100%;
  flex: 1;
  align-items: center;
`;
