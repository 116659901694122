import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import ActionDropdown from "Components/ActionDropdown";
import InfoDialog from "Components/InfoDialog";
import SearchInput from "Components/SearchInput";
import ToggleTree from "Components/ToggleTree";
import RadioField from "Components/fields/RadioField";
import Heading6 from "Components/styleguide/Heading6";
import IconFilter from "Icons/IconFilter";
import Checkbox from "ds/Checkbox";

import * as S from "./NavBar.styles";
import { getCSSVarString, ICON } from "Libs/themes";

const NavBar = ({
  setShowEnvs,
  setTitleFilter,
  setTreeLayout,
  showEnvs,
  sortType,
  titleFilter,
  toggleSort,
  treeLayout
}) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <SearchInput
        placeholder={intl.formatMessage({
          id: "environmentTree.navbar.search",
          defaultMessage: "Search"
        })}
        className="float"
        onChange={e => setTitleFilter(e.target.value)}
        value={titleFilter}
        id="project-environment-search"
      />
      <ActionDropdown
        id="environments-filter"
        closeOnContentClick={false}
        label={intl.formatMessage({
          id: "environmentTree.navbar.filter.label",
          defaultMessage: "Filter"
        })}
        icon={
          <span className="icon">
            <IconFilter color={"var(--icon-ebony-light-fill,var(--icon-ebony-light,var(--ebony-light)))"} />
          </span>
        }
        iconAfter={true}
        withArrow={false}
        withClose={true}
      >
        <Heading6 className="title">
          {intl.formatMessage({
            id: "environmentTree.navbar.filter.label",
            defaultMessage: "Filter environments"
          })}
        </Heading6>
        <hr />
        <Heading6>
          {intl.formatMessage({
            id: "environmentTree.navbar.filter.show",
            defaultMessage: "Show"
          })}
        </Heading6>
        <Checkbox
          forId="toggle-inactive"
          label={intl.formatMessage({
            id: "environmentTree.navbar.filter.inactive",
            defaultMessage: "Inactive"
          })}
          checked={showEnvs.inactive}
          onCheckedChanged={() =>
            setShowEnvs({ ...showEnvs, inactive: !showEnvs.inactive })
          }
        />
        <Checkbox
          forId="toggle-active"
          label={intl.formatMessage({
            id: "environmentTree.navbar.filter.active",
            defaultMessage: "Active"
          })}
          checked={showEnvs.active}
          onCheckedChanged={() =>
            setShowEnvs({ ...showEnvs, active: !showEnvs.active })
          }
        />
        <hr />
        <Heading6>
          {intl.formatMessage({
            id: "environmentTree.navbar.filter.sort",
            defaultMessage: "Sort by"
          })}{" "}
          {treeLayout && (
            <InfoDialog
              align="center"
              text={intl.formatMessage({
                id: "environmentTree.navbar.filter.sort_available",
                defaultMessage:
                  "Environments can only be sorted in the list view."
              })}
            />
          )}
        </Heading6>

        {["title", "created_at"].map(elt => (
          <RadioField
            key={`sort-by-${elt}`}
            forId={`sort-by-${elt}`}
            name={elt}
            label={intl.formatMessage({
              id: `environmentTree.navbar.sort.${elt}`
            })}
            value={sortType === elt && !treeLayout}
            onChange={e => toggleSort(e, elt)}
            disabled={treeLayout}
          />
        ))}
      </ActionDropdown>

      <ToggleTree toggleLayout={setTreeLayout} gridLayout={treeLayout} />
    </S.Wrapper>
  );
};

NavBar.propTypes = {
  setShowEnvs: PropTypes.func,
  setTitleFilter: PropTypes.func,
  setTreeLayout: PropTypes.func,
  showEnvs: PropTypes.object,
  sortType: PropTypes.string,
  titleFilter: PropTypes.string,
  toggleSort: PropTypes.func,
  treeLayout: PropTypes.bool
};

export default NavBar;
