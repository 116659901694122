import styled from "styled-components";

import { css, SECTION } from "Libs/themes";

import Heading2 from "Components/styleguide/Heading2";

export const Wrapper = styled.div`
  margin-bottom: 32px;
`;

export const Header = styled.header`
  display: flex;
  margin-bottom: 16px;
`;

export const Title = styled(Heading2)`
  display: flex;
  flex: 1;
  margin: 0;
  align-self: center;
  text-transform: capitalize;
`;

export const Layout = styled.div`
  position: relative;
  padding: 16px 0 32px 0;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));

  @media (max-width: 768px) {
    padding: 0;
  }

  hr {
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
    box-shadow: none;
    height: 1px;
    border: none;
    margin: 16px 0;
    &.full-width {
      margin: 0 -32px 16px;
    }
  }
  .environments-filter-window > section {
    overflow: visible;
  }
  h6 .info-dialog {
    vertical-align: middle;
  }
  .rcs-custom-scroll {
    .rcs-inner-container {
      padding: 0;
      box-sizing: border-box;
    }
    .rcs-custom-scrollbar {
      .rcs-inner-handle {
        background-color:var(--section-dark-tint-background-color,var(--section-dark-tint,var(--dark-tint)));
        width: 4px;
        border-radius: 4px;
        opacity: 1;
      }
    }
  }
`;

export const TreeWrapper = styled.div`
  padding: 16px 32px 0 32px;

  @media (max-width: 768px) {
    padding: 16px 16px 0 16px;
  }

  ol {
    margin: 0;
    padding: 0;
  }
`;
