import styled from "styled-components";
import ChevronBase from "Icons/ChevronIcon";

import { getCSSVarString, LINK, css } from "Libs/themes";

export const TabsLayout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 82vw;
  overflow: visible;
`;

export const Chevron = styled(ChevronBase)`
  margin-left: 8px;
`;

export const DropdownPanel = styled.div`
  background: white;
  border-radius: 2px;
  box-shadow: 0px 4px 8px 0px rgba(152, 160, 171, 0.4);
  padding: 18px 24px 0;
  margin-top: 4px;
  position: absolute;
  z-index: 1;
  min-width: 300px;
  max-width: 360px;
`;

export const DropdownTab = styled.div`
  display: flex;
  font-weight: 400;
  text-transform: capitalize;
  a:not(.button) {
    text-transform: uppercase;
    font-size: 15px !important;
    height: 48px !important;
    text-decoration: none;
    color:var(--link-ebony-light-color,var(--link-ebony-light,var(--ebony-light)));
    &.active,
    &:hover {
      color: var(--link-skye-dark-color,var(--link-skye-dark,var(--skye-dark))) !important;
    }

    :after {
      content: none;
    }

    :focus,
    :active {
      border: 1px solid transparent;
      box-shadow: none;
    }
  }
  &.right {
    margin-left: auto;
    padding-right: 0;
  }
  &:first-child {
    padding-left: 0;
  }
`;

export const Tab = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 80%;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1.6px;
  a:not(.button) {
    text-decoration: none;
    text-transform: uppercase;
    color:var(--link-ebony-light-color,var(--link-ebony-light,var(--ebony-light)));
    &.active {
      color: ${props =>
        props.subNavActive ||"var(--link-night-color,var(--link-night,var(--night)))"};
    }
    :after {
      content: none;
    }

    :focus,
    :active {
      border: 1px solid transparent;
      box-shadow: none;
    }
  }
  &.right {
    margin-left: auto;
    padding-right: 0;
  }
  &:first-child {
    padding-left: 0;
  }
`;
