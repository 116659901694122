import React from "react";
import { useTooltip, TooltipPopup } from "@reach/tooltip";
import Portal from "@reach/portal";
import "@reach/tooltip/styles.css";

const TOOLTIP_VERTICAL_OFFSET_IN_PIXELS = 8;
const TOOLTIP_FONT_SIZE = "13px";
const TOOLTIP_FONT_WEIGHT = 600;
const TOOLTIP_BG_COLOR = "var(--tooltip-bg, #302F45)";
const TOOLTIP_FG_COLOR = "var(--tooltip-fg, #FFFFFF)";
const DROPSHADOW = "0px 4px 24px rgba(152,160,171, 0.4)";
const DEFAULT_BORDER_RADIUS = "2px";
const FONT_FAMILY = "Open Sans, sans-serif";

// Sucks, but it's the only way to do it for now
// The reason is that we don't have access to the height of
// the tooltip rectangle outside of the TooltipPopup component
// and the triangle is created outside of it, so we need to know
// this value to position the triangle on top of the tooltip.
const TOOLTIP_HEIGHT_IN_PIXELS = 27;

// This is what @reach/tooltip will pass as arguments to its position
// prop.
type PRect = Partial<DOMRect> & {
  readonly bottom: number;
  readonly height: number;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly width: number;
};

type Props = {
  children: React.ReactElement;
  label: string;
  "aria-label"?: string;
};

// The Triangle. We position it relative to the trigger, not the popup
// so that collisions don't have a triangle pointing off to nowhere.
// Using a Portal may seem a little extreme, but we can keep the
// positioning logic simpler here instead of needing to consider
// the popup's position relative to the trigger and collisions
function Triangle({
  rect,
  pointing = "down"
}: {
  rect: PRect | null;
  pointing: "down" | "up";
}) {
  const triangleOffset = TOOLTIP_VERTICAL_OFFSET_IN_PIXELS - 2;

  const styleDown = {
    left: rect ? rect.left - triangleOffset + rect.width / 2 : "",
    top: rect ? rect.bottom + window.scrollY + 3 : "",
    transform: "rotate(135deg)"
  };

  const styleUp = {
    left: rect ? rect.left - triangleOffset + rect.width / 2 : "",
    top: rect ? rect.top - triangleOffset * 2.4 + window.scrollY : "",
    transform: "rotate(-45deg)"
  };

  const style = pointing === "up" ? styleUp : styleDown;

  return (
    <Portal>
      <div
        style={{
          position: "absolute",
          width: "12px",
          height: "12px",
          zIndex: 2,
          background: TOOLTIP_BG_COLOR,
          borderRadius: "0 0 0 3px",
          clipPath: "polygon(0% 0%, 100% 100%, 0% 100%)",
          ...style
        }}
      />
    </Portal>
  );
}

function Tooltip({ children, label, "aria-label": ariaLabel }: Props) {
  const [trigger, tooltip] = useTooltip();

  const { isVisible, triggerRect } = tooltip;

  const hasSpaceBelow =
    (triggerRect?.bottom || 0) +
      TOOLTIP_VERTICAL_OFFSET_IN_PIXELS +
      TOOLTIP_HEIGHT_IN_PIXELS >
    window.innerHeight;

  const trianglePointing = hasSpaceBelow ? "up" : "down";
  const capitalizedLabel = capitalizeSentence(label);

  return (
    <>
      {React.cloneElement(children, trigger)}
      {!!label.length && (
        <>
          {isVisible && (
            <Triangle rect={triggerRect} pointing={trianglePointing} />
          )}
          <TooltipPopup
            {...tooltip}
            label={capitalizedLabel}
            aria-label={ariaLabel}
            style={{
              background: TOOLTIP_BG_COLOR,
              color: TOOLTIP_FG_COLOR,
              boxShadow: DROPSHADOW,
              border: "none",
              fontFamily: FONT_FAMILY,
              fontSize: TOOLTIP_FONT_SIZE,
              fontWeight: TOOLTIP_FONT_WEIGHT,
              borderRadius: DEFAULT_BORDER_RADIUS,
              padding: "6px 16px"
            }}
            position={getTooltipPosition}
          />
        </>
      )}
    </>
  );
}

function capitalizeSentence(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// It seems @reach/tooltip can also pass null or undefined as arguments
type PositionProp = PRect | null | undefined;

// Calculates the position we need to give to the tooltip to meet following requirements:
// - Be horizontally centered if there is enough space to do so.
// - Avoid collisions with the left or right sides of the screen by displacing the tooltip
// - Position the tooltip below the trigger by default
// - Position the tooltip above the trigger if there is no space below
function getTooltipPosition(
  triggerRect: PositionProp,
  tooltipRect: PositionProp
) {
  if (!triggerRect || !tooltipRect) {
    return {};
  }
  return {
    left: getTooltipX(triggerRect, tooltipRect),
    top: getTooltipY(triggerRect, tooltipRect)
  };
}

function getTooltipX(triggerRect: PRect, tooltipRect: PRect) {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;

  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;

  return Math.min(Math.max(2, left), maxLeft) + window.scrollX;
}

function getTooltipY(triggerRect: PRect, tooltipRect: PRect) {
  const belowTrigger =
    triggerRect.bottom + TOOLTIP_VERTICAL_OFFSET_IN_PIXELS + window.scrollY;

  const aboveTrigger =
    triggerRect.top -
    tooltipRect.height -
    TOOLTIP_VERTICAL_OFFSET_IN_PIXELS +
    window.scrollY;

  const hasSpaceBelow =
    tooltipRect.height +
      triggerRect.bottom +
      TOOLTIP_VERTICAL_OFFSET_IN_PIXELS +
      2 <
    window.innerHeight;

  return hasSpaceBelow ? belowTrigger : aboveTrigger;
}

export default Tooltip;
