import { fromJS, Map } from "immutable";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MapStoreStateType,
  SetErrorMessageAction,
  StoreMapStateType
} from "Reducers/types";
import type PaymentSource from "platformsh-client/types/model/PaymentSource";

export const getPaymentSource = createAsyncThunk(
  "app/paymentSource",
  async () => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const paymentSource = await client.getPaymentSource();
    return paymentSource;
  }
);

export const setError = (
  state: StoreMapStateType,
  action: SetErrorMessageAction
) => state.set("errors", action.error.message).set("loading", false);

const paymentSource = createSlice({
  name: "paymentSource",
  initialState: Map({ data: Map(), loading: "idle" }) as StoreMapStateType,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPaymentSource.pending, (state: StoreMapStateType) =>
      state.set("loading", true)
    );

    builder.addCase(
      getPaymentSource.fulfilled,
      (state: StoreMapStateType, action: PayloadAction<{} | PaymentSource>) =>
        state.setIn(["data"], fromJS(action.payload)).set("loading", false)
    );

    builder.addCase(
      getPaymentSource.rejected,
      (state: StoreMapStateType, action: SetErrorMessageAction) =>
        setError(state, action)
    );
  }
});

export default paymentSource.reducer;

export const paymentSourceSelector = (state: MapStoreStateType) => {
  return state.paymentSource.get("data");
};

export const paymentSourceLoadingSelector = (state: MapStoreStateType) => {
  return state.paymentSource.get("loading");
};
