import React from "react";
import PropTypes from "prop-types";

import { BILLING_PATH_MATCH, SETTINGS_PATH_MATCH } from "Constants/constants";

import * as S from "./styles";

const getAccountTabs = organizationId => {
  return [
    {
      name: "profile",
      route: `/-/users/${organizationId}/settings`
    },
    {
      name: "connected_accounts",
      route: `/-/users/${organizationId}/settings/accounts`,
      disabled: !process.env.ENABLE_CONNECTED_ACCOUNTS_ROUTE
    },
    {
      name: "security",
      route: `/-/users/${organizationId}/settings/security`,
      disabled: !process.env.ENABLE_ACCOUNT_SECURITY_ROUTE
    },
    {
      name: "tokens",
      route: `/-/users/${organizationId}/settings/tokens`
    },
    {
      name: "ssh-keys",
      route: `/-/users/${organizationId}/settings/ssh-keys`
    }
  ];
};

const getOrganizationSettingsTabs = (organizationId, organization) => {
  return [
    {
      name: "settings",
      route: `/${organizationId}/-/settings`
    },
    {
      name: "users",
      route: `/${organizationId}/-/settings/users`,
      disabled: !organization?.hasLink("members")
    }
  ];
};

const getBillingTabs = organizationId => {
  return [
    {
      name: "details",
      route: `/-/users/${organizationId}/billing`
    },
    {
      name: "plan",
      route: `/-/users/${organizationId}/billing/plan`
    },
    {
      name: "history",
      route: `/-/users/${organizationId}/billing/history`,
      disabled: process.env.VENDOR_URL
    },
    {
      name: "vouchers",
      route: `/-/users/${organizationId}/billing/vouchers`
    }
  ];
};

const getOrganizationBillingTabs = (organizationId, organization) => {
  const baseURL = `/${organizationId}/-/billing`;
  const tabs = [];
  if (organization?.hasLink("orders")) {
    tabs.push({
      name: "details",
      route: baseURL
    });
  }

  if (organization?.hasLink("estimate-subscription")) {
    tabs.push({
      name: "plan",
      route: `${baseURL}/plan`
    });
  }

  if (organization?.hasLink("orders")) {
    tabs.push({
      name: "history",
      route: `${baseURL}/history`,
      disabled: process.env.VENDOR_URL
    });
  }

  if (organization?.hasLink("vouchers")) {
    tabs.push({
      name: "vouchers",
      route: `${baseURL}/vouchers`
    });
  }

  return tabs;
};

const InsideNavBar = ({
  organizationId,
  currentPathName,
  organization,
  username,
  tabConfig
}) => {
  const getTabsByPath = () => {
    if (BILLING_PATH_MATCH.test(currentPathName)) {
      return getBillingTabs(username);
    }
    if (SETTINGS_PATH_MATCH.test(currentPathName)) {
      return getAccountTabs(username);
    }
    if (new RegExp(`^/${organizationId}/-/settings`).test(currentPathName)) {
      return getOrganizationSettingsTabs(organizationId, organization);
    }
    if (new RegExp(`^/${organizationId}/-/billing`).test(currentPathName)) {
      return getOrganizationBillingTabs(organizationId, organization);
    }
  };

  return (
    <S.NavBar
      currentPathName={currentPathName}
      tabs={tabConfig || getTabsByPath().filter(tab => !tab.disabled)}
    />
  );
};

InsideNavBar.propTypes = {
  username: PropTypes.string,
  organizationId: PropTypes.string,
  organization: PropTypes.object,
  currentPathName: PropTypes.string,
  tabConfig: PropTypes.array
};

export default InsideNavBar;
