import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment-timezone";

import ModalWrapper from "Components/Modal";
import { Button, ButtonWrapper } from "ds/Button";

import * as S from "./ModalWelcome.styles";

const ModalWelcome = ({ closeModal, currentTime, isOpen, user }) => {
  const intl = useIntl();
  if (!user?.current_trial) return null;
  const { expiration, spend } = user.current_trial;

  // formatted string of amount that will display in modal
  const amount = spend?.formatted || "$30";

  const expirationDate = moment(expiration);

  // Add 1 day to get total duration, inclusive of start date
  const duration = expirationDate.diff(currentTime, "days") + 1;

  return (
    <ModalWrapper
      closeModal={closeModal}
      id="trial-modal-welcome"
      isOpen={isOpen}
      modalClass="modal-small"
      title={intl.formatMessage({
        defaultMessage: "Free trial starting",
        id: "trial.modal.welcome.title"
      })}
    >
      <S.ModalBody>
        <FormattedMessage
          defaultMessage={`Creating your first project starts your free trial, where you can spend up to ${amount} in the next ${duration} days. At the end of your trial you'll be prompted to add your billing details to keep your projects running.`}
          id="trial.modal.welcome.text"
          values={{
            amount,
            duration
          }}
        />
      </S.ModalBody>

      <ButtonWrapper hasSpacing justifyContent="end">
        <Button id="welcome-modal-button" onClick={closeModal}>
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

ModalWelcome.propTypes = {
  closeModal: PropTypes.func,
  currentTime: PropTypes.object,
  isOpen: PropTypes.bool,
  user: PropTypes.object
};

export default ModalWelcome;
