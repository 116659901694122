import { fromJS, Map, List } from "immutable";
import { push as reactRouterReduxPush } from "connected-react-router";

import { loadLastVisitedProjects, loadProjects } from "Reducers/project";
import { getPlans } from "Reducers/plan";
import { loadCurrentUserProfile } from "Reducers/profile";
import {
  LOAD_ORGANIZATIONS_SUCCESS,
  LOAD_REF_ORGANIZATIONS_SUCCESS
} from "Reducers/organization";
import { getOrganizationRegions } from "Reducers/organization/region";
import { MapStoreStateType, StoreMapStateType } from "Reducers/types";

import { ORGANIZATION_ID_FIELD } from "Constants/constants";

import { loadSubscriptions } from "../subscription";

import { getEnvironmentDescriptionId } from "Libs/utils";
import parse from "Libs/urlParser";

import logger from "Libs/logger";

import { Action, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface LocationAction extends Action {
  payload: {
    location: {
      pathname: string;
    };
  };
}

export type PushArgs = {
  organizationId?: string;
  projectId?: string;
  environmentId?: string;
};

export const init = createAsyncThunk(
  "app/init",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(getMe());
      dispatch(getPlans());
    } catch (error) {
      logger(error as Error, {
        action: "init"
      });
      return rejectWithValue(error as Error);
    }
  }
);

export const getMe = createAsyncThunk(
  "app/get_me",
  async (
    reload: boolean | undefined = false,
    { rejectWithValue, dispatch }
  ) => {
    try {
      // Load the current user
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const me = await client.getAccountInfo(true);

      if (!reload) {
        if (!process.env.ENABLE_ORGANIZATION) {
          // Load all the users subscriptions at first load to avoid race condition with loadSubscription

          // TODO: Type issue to be fixed when loadSubscriptions() source
          // is converted to typescript
          // @ts-ignore
          dispatch(loadSubscriptions({ organizationId: me.username }));
        }

        if (process.env.ENABLE_ORGANIZATION) {
          try {
            const res = await Promise.all([
              client.getOrganizations({
                userId: me?.uuid
              }),
              me.getOrganizations()
            ]);

            dispatch({
              type: LOAD_REF_ORGANIZATIONS_SUCCESS,
              payload: res[1]
            });

            dispatch({
              type: LOAD_ORGANIZATIONS_SUCCESS,
              payload: res[0]
            });

            // Load regions for all organizations
            res[0].forEach(o => {
              dispatch(getOrganizationRegions({ organizationId: o?.name }));
            });
          } catch (err) {
            logger(err as Error, {
              action: "LOAD_REF_ORGANIZATIONS"
            });
          }
        }

        dispatch(loadLastVisitedProjects());
      }

      return { ...me };
    } catch (error) {
      logger(error as Error, {
        action: "GET_ME_START"
      });
      return rejectWithValue(error as Error);
    } finally {
      await setTimeout(() => {
        dispatch(loadCurrentUserProfile());
        dispatch(loadProjects());
      }, 0);
    }
  }
);

export const push = createAsyncThunk(
  "app/push",
  async (
    { path, args }: { path: string; args: PushArgs },
    { getState, dispatch }
  ) => {
    const descriptionArgs = { ...args };

    if (args.organizationId) {
      const organization = (getState() as MapStoreStateType).organization.getIn(
        ["data", args.organizationId]
      );

      descriptionArgs.organizationId = organization[ORGANIZATION_ID_FIELD];
    }

    if (args.projectId) {
      descriptionArgs.projectId = args.projectId;
    }

    if (args.environmentId) {
      descriptionArgs.environmentId = getEnvironmentDescriptionId(
        getState,
        args.environmentId
      );
    }

    const url = parse(path, descriptionArgs);

    dispatch(reactRouterReduxPush(url));
  }
);

const app = createSlice({
  name: "app",
  initialState: Map() as StoreMapStateType,
  reducers: {},
  extraReducers: builder => {
    //INIT

    builder.addCase(init.pending, (state: StoreMapStateType) =>
      state.set("loading", true)
    );

    builder.addCase(init.fulfilled, (state: StoreMapStateType) => {
      return state.setIn(
        ["routeHistory", "currentRoute"],
        window.location.pathname
      );
    });

    builder.addCase(init.rejected, (state: StoreMapStateType, action) => {
      return state.set("error", action.payload);
    });

    builder.addCase(getMe.pending, (state: StoreMapStateType) =>
      state.set("loading", true)
    );

    builder.addCase(
      getMe.fulfilled,
      (state: StoreMapStateType, { payload }) => {
        return state.set("me", fromJS(payload));
      }
    );

    builder.addCase(getMe.rejected, (state: StoreMapStateType, { payload }) => {
      return state.set("error", payload);
    });

    builder.addCase(
      "@@router/LOCATION_CHANGE",
      (state, action: LocationAction) => {
        // This action doesn't contain the previous route so we have to log each
        // route in the store, then move it to a list that contains the history.
        // Then use .toSet().toList() to ensure no duplicates are added to the history.
        return state
          .setIn(
            ["routeHistory", "currentRoute"],
            action.payload.location.pathname
          )
          .setIn(
            ["routeHistory", "previousRoutes"],
            state
              .getIn(["routeHistory", "previousRoutes"], List())
              .unshift(state.getIn(["routeHistory", "currentRoute"], null))
              .toSet()
              .toList()
          );
      }
    );
  }
});

export default app.reducer;

export const meSelector = (state: MapStoreStateType) => state.app.get("me");
