import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const Back = ({ color = "#4b6180" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`back-icon-${uid}`}
    >
      <title id={`back-icon-${uid}`}>Back</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90883 10.708L12.1963 6.42048C12.5346 6.0822 12.5346 5.53375 12.1963 5.19548C11.8581 4.8572 11.3096 4.8572 10.9713 5.19548L5.29094 10.8759C4.90042 11.2664 4.90042 11.8996 5.29094 12.2901L10.9713 17.9705C11.3096 18.3088 11.8581 18.3088 12.1963 17.9705C12.5346 17.6322 12.5346 17.0838 12.1963 16.7455L7.90883 12.458H17.7088C18.1921 12.458 18.5838 12.0662 18.5838 11.583C18.5838 11.0997 18.1921 10.708 17.7088 10.708H7.90883Z"
        fill={color}
      />
    </svg>
  );
};

Back.propTypes = {
  color: PropTypes.string
};

export default Back;
