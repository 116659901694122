import styled from "styled-components";

import { SECTION, css } from "Libs/themes";

const ContentPaneLayout = styled.form`
  width: 100%;

  .field,
  .toggle-field {
    margin-bottom: 32px;
  }
  .toggle-field .field {
    margin-bottom: 0;
  }
  .copyable-area {
    margin-bottom: 32px;
  }
  h4 {
    display: flex;
    align-items: center;
  }
  p {
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    font-size: 15px;
    margin: 0 0 24px 0;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    span {
      white-space: normal;
    }
  }
`;

export default ContentPaneLayout;
