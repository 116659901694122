import { fromJS, Map } from "immutable";

import { Action, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrganizationId } from "Libs/utils";
import { MapStoreStateType, StoreMapStateType } from "Reducers/types";

import type OrganizationRegion from "platformsh-client/types/model/OrganizationRegion";
import type CursoredResult from "platformsh-client/types/model/CursoredResult";

export type SetOrganizationTokenAction = {
  error: {
    message?: string | undefined;
  };
};

export type OrganizationRegionSelectorProps = {
  organizationId: string;
};

export interface OrganizationRegionAction extends Action {
  payload: {
    result: CursoredResult<OrganizationRegion>;
    availableRegions: OrganizationRegion[];
    byUrl: Record<string, string>;
  };
  meta: {
    arg: {
      organizationId: string;
    };
  };
}

export const getOrganizationRegions = createAsyncThunk(
  "app/organization/region",
  async ({ organizationId }: { organizationId: string }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const result = await client.getOrganizationRegions(
      getOrganizationId(getState, organizationId),
      {}
    );

    const allRegions = result?.items;

    const availableRegions = allRegions.filter(
      region => region.available == true // Double equals because some available keys are strings, and some aren't.
    );

    const byUrl = allRegions.reduce(
      (urls: Record<string, string>, region: OrganizationRegion) => {
        const url = region.endpoint.replace("https://", "").replace("/api", "");

        urls[url] = region.project_label || "";
        return urls;
      },
      {}
    );

    return {
      result,
      byUrl,
      availableRegions
    };
  }
);

const setError = (
  state: StoreMapStateType,
  action: SetOrganizationTokenAction
) => state.set("errors", action.error.message).set("loading", false);

const organizationRegion = createSlice({
  name: "organizationRegion",
  initialState: Map({
    data: Map(),
    loading: "idle"
  }) as StoreMapStateType,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrganizationRegions.pending, (state: StoreMapStateType) =>
        state.set("loading", true)
      )
      .addCase(
        getOrganizationRegions.fulfilled,
        (state: StoreMapStateType, action: OrganizationRegionAction) => {
          const { organizationId } = action.meta.arg;
          const availableRegions = action.payload.availableRegions;

          return state
            .setIn(
              ["data", organizationId],
              fromJS(
                availableRegions.reduce(
                  (
                    acc: { [key: string]: OrganizationRegion },
                    region: OrganizationRegion
                  ) => {
                    acc[region.id] = region;
                    return acc;
                  },
                  {}
                )
              )
            )
            .setIn(
              ["byRegion", organizationId],
              fromJS(
                availableRegions.reduce(
                  (
                    accumulator: { [key: string]: OrganizationRegion[] },
                    current: OrganizationRegion
                  ) => {
                    if (!accumulator[current.zone]) {
                      accumulator[current.zone] = [current];
                      return accumulator;
                    }

                    accumulator[current.zone].push(current);
                    return accumulator;
                  },
                  {}
                )
              )
            )
            .setIn(["byUrl", organizationId], fromJS(action.payload.byUrl))
            .setIn(["errors", organizationId], false)
            .set("loading", false);
        }
      )
      .addCase(
        getOrganizationRegions.rejected,
        (state: StoreMapStateType, action: SetOrganizationTokenAction) =>
          setError(state, action)
      );
  }
});

export default organizationRegion.reducer;

export const organizationRegionSelector = (
  state: MapStoreStateType,
  props: OrganizationRegionSelectorProps
) => state.organizationRegion.getIn(["data", props.organizationId]);
