import styled, { css as styledCSS } from "styled-components";

import { getCSSVarString, css, ICON, LINK, SECTION } from "Libs/themes";

import LabelUnstyled from "Components/fields/Label";
import { EnvironmentListBar as EnvironmentListBarUnstyled } from "../";

export const Wrapper = styled.div``;

export const SortWrapper = styled.div`
  justify-content: space-between;
  margin: 0;
  padding: 0 32px 8px 32px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  border-bottom: 1px solid
    var(--section-skye-light-border-color,var(--section-skye-light,var(--skye-light)));
  display: none;
  @media (min-width: 1025px) {
    display: flex;
  }
`;

export const Label = styled(LabelUnstyled)`
  display: flex;
  margin: 0;
  align-self: center;
  color:var(--link-slate-color,var(--link-slate,var(--slate)));
  cursor: pointer;

  svg {
    width: 24px;
  }
`;

export const SortBtn = styled.button`
  display: flex;
  width: 100px;
  margin-right: 40px;
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  text-decoration: none;
  align-items: center;

  ${({ kind }) =>
    kind === "title" &&
    styledCSS`
    width: auto;
    margin-right: auto;
  `}

  ${({ kind }) =>
    kind === "deployed_at" &&
    styledCSS`
    margin-left: 16px;
  `}

  &:focus {
    outline: none;
  }

  svg {
    transform: rotate(${({ dir }) => (dir === "desc" ? 180 : 0)}deg);
  }

  ${({ active }) =>
    active &&
    styledCSS`
    svg path {
      fill:var(--icon-skye-fill,var(--icon-skye,var(--skye)));
    }
  `}
`;

export const EnvironmentListBar = styled(EnvironmentListBarUnstyled)`
  width: auto;
  height: 56px;
  line-height: 56px;
  margin: 0 32px;
  border-top: 1px solid
    var(--section-skye-light-border-color,var(--section-skye-light,var(--skye-light)));

  &:first-child {
    border: 0;
  }

  @media (max-width: 768px) {
    margin: 0 16px;
  }
`;
