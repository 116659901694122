import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { getCSSVarString, SECTION } from "Libs/themes";

const Label = styled.label`
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
  line-height: 18px;
  color: ${props => props.color ||"var(--section-slate-color,var(--section-slate,var(--slate)))"};
  ${semiBoldAlias};
  ${props =>
    !props.preserveCase &&
    `&:first-letter {
  text-transform: uppercase;
};`}
  &.hidden-label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export default Label;
