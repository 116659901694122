import { FC } from "react";
import styled from "styled-components";

import Icon, { WrappingIconProps } from "../../Icon";

export const WarningIconBackground = styled.path`
  fill: var(--icon-warning, #000);
`;

export const WarningIconForeground = styled.path`
  fill: var(--icon-warning-inverse, #fff);
`;

const WarningIcon: FC<WrappingIconProps> = (props: WrappingIconProps) => (
  <Icon {...props} title="warning">
    <WarningIconBackground d="M13.4697 3.80093L21.8171 18.6448C22.3736 19.7127 21.5945 21.101 20.3702 20.9942H3.67542C2.45113 20.9942 1.56074 19.7127 2.22854 18.6448L10.5759 3.80093C11.2437 2.73302 12.8019 2.73302 13.4697 3.80093Z" />
    <WarningIconForeground
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19Z"
    />
    <WarningIconForeground
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15C11.4477 15 11 14.5523 11 14V9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9V14C13 14.5523 12.5523 15 12 15Z"
    />
  </Icon>
);

export default WarningIcon;
