import { CONFIG_DIR_NAME } from "../documentationUrls";

const getDefaultTemplateWizardSteps = intl => {
  return [
    {
      id: "cli",
      label: "Download the CLI",
      title: "Download the CLI",
      subtitle: "(Command Line Interface)",
      required: false,
      bodyText: `<p>To install the CLI, use the command for either OSX or Windows as shown.</p><p>For more info about our CLI check out our <a href=${intl.formatMessage(
        { id: "links.documentation.CLI" }
      )} target="_blank" rel="noopener noreferrer">documentation</a> or take a look at our <a href=${intl.formatMessage(
        { id: "links.CLI_repository" }
      )} target="_blank" rel="noopener noreferrer">CLI source code</a>.</p>`,
      copyCode: [
        {
          label: "OSX or Linux Installer",
          code: [
            `curl -sS ${intl.formatMessage({ id: "links.CLI_download" })} | php`
          ]
        },
        {
          label: "Windows Installer",
          code: [
            `curl ${intl.formatMessage({
              id: "links.CLI_download"
            })} -o cli-installer.php`,
            "php cli-installer.php"
          ]
        }
      ]
    },
    {
      id: "sshKey",
      label: "Add your SSH key",
      title: "Add your SSH key",
      required: true,
      bodyText: `<p>To deploy your code to ${intl.formatMessage({
        id: "app_name"
      })} you'll need Git installed on your local machine and your <a href=${intl.formatMessage(
        { id: "links.documentation.SSH_keys" }
      )} target="_blank" rel="noopener noreferrer">SSH key</a>. You can paste your Public SSH key in the box below (or do so later in your Account Settings).</p><p>Configuring an SSH key will also allow you to connect to your running applications and services.</p><p>If you don't have an SSH key configured we recommend following GitHub's excellent SSH <a href="https://help.github.com/en/articles/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent" target="_blank" rel="noopener noreferrer">tutorial</a>.</p>`
    },
    {
      id: "download",
      label: "Download your project",
      title: "Download your project to start using it",
      required: true,
      bodyText: `<p>Use the ${intl.formatMessage({
        id: "CLI_command_name"
      })} get CLI command to download your project template so you can start modifying it.</p><p><br /></p><p>Visit our <a href=${intl.formatMessage(
        { id: "links.documentation.download_code" }
      )} target="_blank" rel="noopener noreferrer">docs</a> for more information.</p>`,
      copyCode: [
        {
          label: "CLI command",
          code: [
            `${intl.formatMessage({
              id: "CLI_command_name"
            })} get <project ID>`
          ]
        }
      ]
    },
    {
      id: "configure",
      label: "Configure your project",
      title: "Customize your infrastructure",
      required: true,
      bodyText: `<p>Customize the configuration files included in your template to change your project infrastructure.</p><p>Your required files:</p><p><a href=${intl.formatMessage(
        { id: "links.documentation.container_configuration" }
      )} target="_blank" rel="noopener noreferrer"><code>.platform.app.yaml</code></a><br><a href=${intl.formatMessage(
        { id: "links.documentation.services" }
      )} target="_blank" rel="noopener noreferrer"><code>.platform/services.yaml</code></a><br><a href=${intl.formatMessage(
        { id: "links.documentation.routes" }
      )} target="_blank" rel="noopener noreferrer"><code>.platform/routes.yaml</code></a></p><p></p><p>See our <a href=${intl.formatMessage(
        { id: "links.documentation.own_code_project_configuration" }
      )} target="_blank" rel="noopener noreferrer">documentation</a> for more information.</p>`,
      copyCode: [
        {
          label: "Application code structure",
          code: [
            `├── ${CONFIG_DIR_NAME}`,
            "│   ├── routes.yaml",
            "│   └── services.yaml",
            "├── .platform.app.yaml",
            "└── < application code >"
          ],
          noCopy: true
        }
      ]
    }
  ];
};

export default getDefaultTemplateWizardSteps;
