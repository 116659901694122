import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";

import client, { request } from "Libs/platform";

const ContextLayout = styled.div`
  color: black;
  background-color: white;
`;

function loadApp(app) {
  return Function(`return function(define) { return ${app} }`)(); // eslint-disable-line
}

function Context({ manifestPath, ...props }) {
  // eslint-disable-next-line react/display-name
  const [C, sc] = useState(() => () => <div>Loading...</div>);

  const theme = useTheme();

  useEffect(() => {
    function define(_, _2, appFunc) {
      const exports = appFunc(React, styled);

      sc(() => exports.Main);
    }

    // load the manifest
    fetch(manifestPath)
      .then(r => r.json())
      .then(manifest => {
        fetch(manifest.url || "http://localhost:3333/index.js")
          .then(r => r.text())
          .then(script => {
            const application = loadApp(script);
            application(define);
          });
      });
  }, [manifestPath]);

  return (
    <ContextLayout>
      <C client={client} request={request} theme={theme} {...props} />
    </ContextLayout>
  );
}

Context.propTypes = {
  manifestPath: PropTypes.string
};

export default Context;
