import type { ClientConfiguration } from "platformsh-client/types/config";

const onBeforeRedirect = (location: string) => {
  if (
    location !== "/-/logout" &&
    location !== "/-/post-logout" &&
    location !== "/" &&
    location !== process.env.POST_LOGOUT_URL &&
    location !== process.env.LOGOUT_URL
  ) {
    localStorage.setItem("auth-redirect-uri", location);
  }
};

// Use the default JS client on production
let config: ClientConfiguration = {
  response_mode: "web_message",
  response_type: "code",
  prompt: "none",
  api_url: process.env.API_PROXY_URL as string,
  account_url: process.env.API_PROXY_URL,
  client_id: process.env.API_CLIENT_ID,
  authentication_url: process.env.AUTH_URL,
  authorization: process.env.AUTH_URL
    ? `${process.env.AUTH_URL}/oauth2/authorize`
    : `${process.env.ACCOUNTS_URL}/oauth2/authorize`,
  logout_url: `${process.env.ACCOUNTS_URL}/user/logout`,
  base_url: process.env.API_PROXY_URL,
  onBeforeRedirect
};

const hasRequiredConfig =
  process.env.API_PROXY_URL &&
  process.env.API_CLIENT_ID &&
  process.env.ACCOUNTS_URL;

// @todo: Refactor this so it we don't have as much duplication.
if (process.env.NODE_ENV !== "production") {
  if (process.env.NODE_ENV === "pre-production") {
    config = {
      base_url: "http://localhost:3000",
      client_id: "platform@ui.psh.dev",
      redirect_uri: "http://localhost:3000/",
      access_token: "alice",
      scope: [],
      authorization: "https://accounts.internal.platform.sh/oauth2/authorize",
      api_url: "http://localhost:3000/api",
      onBeforeRedirect
    };
  } else {
    config = {
      response_mode: "web_message",
      response_type: "code",
      prompt: "none",
      base_url: process.env.API_PROXY_URL,
      client_id: process.env.API_CLIENT_ID
        ? process.env.API_CLIENT_ID
        : "platform@ui.psh.dev",
      redirect_uri: process.env.REDIRECT_URI
        ? process.env.REDIRECT_URI
        : "http://localhost:3000/",
      authentication_url: process.env.AUTH_URL,
      authorization: process.env.AUTH_URL
        ? `${process.env.AUTH_URL}/oauth2/authorize`
        : "https://accounts.internal.platform.sh/oauth2/authorize",
      api_url: process.env.API_PROXY_URL
        ? process.env.API_PROXY_URL
        : "http://localhost:3000/api",
      scope: [],
      onBeforeRedirect
    };

    if (!hasRequiredConfig) {
      config.access_token = "alice";
    }
  }
}

export default config;
