import React from "react";
import PropTypes from "prop-types";
import Sticker from "ds/Stickers";

const Error = ({ children }) => {
  return children ? <Sticker priority="critical" text={children} /> : null;
};

Error.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Error;
