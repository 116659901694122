import styled from "styled-components";
import { css, HEADER } from "Libs/themes";

export default styled.h2`
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  margin: 0 0 32px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  &:first-letter {
    text-transform: capitalize;
  }
`;
