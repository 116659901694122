import styled from "styled-components";
import { getCSSVarString, LINK, css, BUTTON, ICON } from "Libs/themes";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  background: transparent;
  height: 40px;
  box-sizing: border-box;
  padding: 1px;
  .toggle-button,
  a:not(.close) {
    border: none;
    padding: 4px;
    height: 32px;
    width: 32px;
    min-width: 32px;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 1px;
    display: flex;
    align-items: center;
    svg {
      width: 100%;
      max-width: 100%;
    }
    &:hover {
      background-color:var(--link-snow-background-color,var(--link-snow,var(--snow)));
      box-shadow: ${props =>
        props.theme.name === "dark"
          ? `0px 8px 24px ${getCSSVarString(
              LINK,
              "dark-shade",
              "box-shadow-color"
            )}`
          : "none"};
      svg {
        opacity: 1;
        rect,
        path {
          fill: var(--icon-skye-fill,var(--icon-skye,var(--skye))) !important;
        }
      }
    }
    &:focus {
      outline: none;
      border: 1px solid var(--link-skye-dark-border-color,var(--link-skye-dark,var(--skye-dark)));
      box-shadow: 0 0 2px
        var(--link-skye-dark-box-shadow-color,var(--link-skye-dark,var(--skye-dark)));
      padding: 3px;
    }
    &.active,
    &:active {
      box-shadow: ${props =>
        props.theme.name === "dark"
          ? `0px 8px 24px ${getCSSVarString(
              LINK,
              "dark-shade",
              "box-shadow-color"
            )}`
          : "0 1px 4px rgba(152, 160, 171, 0.6)"};
      border: none !important;
      padding: 4px;
      background-color:var(--link-snow-background-color,var(--link-snow,var(--snow)));
    }
  }
  .active {
    background-color:var(--button-skye-dark-background-color,var(--button-skye-dark,var(--skye-dark)));
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? `0px 8px 24px ${getCSSVarString(
            BUTTON,
            "dark-shade",
            "box-shadow-color"
          )}`
        : "0 1px 4px rgba(152, 160, 171, 0.6)"};
    svg {
      opacity: 1;
      rect,
      path {
        fill: var(--icon-skye-fill,var(--icon-skye,var(--skye))) !important;
      }
    }
  }

  .tooltip {
    width: 300px;
    section > span {
      float: right;
      .close:not(a) {
        padding: 10px;
      }
    }
  }
  h6.title {
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
  }
`;
