import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import ChevronIcon from "Components/icons/ChevronIcon";
import MenuDropdownWrapper from "Components/MenuDropdownWrapper";
import { Map } from "immutable";
import { Link } from "react-router-dom";

import { getUrls } from "Libs/utils";
import { getCSSVarString, ICON } from "Libs/themes";

import MenuLink from "./MenuLink";
import MobileMenu from "./MobileMenu";
import * as S from "./styles";
import { useIntl } from "react-intl";

const UserMenu = ({
  user,
  accountUrl,
  breadcrumbs,
  organizations,
  organizationId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const $layout = useRef(null);

  let organization = organizations && organizations.get(organizationId);

  const formatMessage = useIntl().formatMessage;

  useEffect(() => {
    const handleClickOutside = event => {
      if (!ReactDOM.findDOMNode($layout.current).contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, false);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [$layout]);

  const urls = getUrls({
    accountUrl,
    username: user.username,
    organizationName: organization?.name
  });

  const isVendor = process.env.VENDOR_NAME || process.env.VENDOR_URL;

  const profile = useSelector(({ profile }) =>
    profile?.getIn(["data", user.username], Map())
  )?.toJS();
  const { display_name, picture } = profile || {};

  return (
    <>
      <MobileMenu breadcrumbs={breadcrumbs} user={user} urls={urls} />
      <S.Layout ref={$layout}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className={`user-menu-toggle${isOpen ? " open" : ""}${
            user.picture && !user.picture.endsWith("user-default.png")
              ? " picture"
              : ""
          }`}
          aria-label="User menu"
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          {picture && !picture.endsWith("user-default.png") ? (
            <img src={picture} alt={display_name} />
          ) : (
            display_name
          )}
          <ChevronIcon color={`var(--icon-granite-fill,var(--icon-granite,var(--granite)))`} />
        </button>
        {/*
        TODO We need to completely seperate User menu from organization menu as the
        Code below is starting to look unmaintanable due to conditions
        */}
        <MenuDropdownWrapper className="menu-dropdown" isOpen={isOpen}>
          <ul>
            {process.env.ENABLE_ORGANIZATION && (
              <S.OrganizationLabel>{organization?.label}</S.OrganizationLabel>
            )}
            {process.env.CUSTOM_VENDOR_CONSOLE_URL &&
              process.env.CUSTOM_VENDOR_CONSOLE_TITLE && (
                <li>
                  <MenuLink
                    label={process.env.CUSTOM_VENDOR_CONSOLE_TITLE}
                    url={process.env.CUSTOM_VENDOR_CONSOLE_URL}
                  />
                </li>
              )}
            {!process.env.ENABLE_ORGANIZATION && (
              <>
                <li>
                  <MenuLink
                    label={formatMessage({ id: "menu.account" })}
                    url={urls.profileUrl}
                  />
                </li>
                {(process.env.ENABLE_BILLING ||
                  process.env.CUSTOM_BILLING_URL) && (
                  <li>
                    <MenuLink
                      label={formatMessage({ id: "menu.billing" })}
                      url={urls.billingUrl}
                    />
                  </li>
                )}
              </>
            )}
            {process.env.ENABLE_ORGANIZATION &&
              organizationId &&
              organization &&
              // Only a member or owner of an org would have the self link
              organization?.hasLink("self") && (
                <>
                  <li>
                    <MenuLink
                      label={formatMessage({ id: "menu.settings" })}
                      url={urls.organizationSettingsUrl}
                    />
                  </li>
                  {organization?.hasLink("members") && (
                    <li>
                      <MenuLink
                        label={formatMessage({ id: "menu.users" })}
                        url={urls.organizationUsersUrl}
                      />
                    </li>
                  )}
                  {organization?.hasLink("orders") &&
                    (process.env.ENABLE_BILLING ||
                      process.env.CUSTOM_BILLING_URL) && (
                      <li>
                        <MenuLink
                          label={formatMessage({ id: "menu.billing" })}
                          url={urls.organizationBillingUrl}
                        />
                      </li>
                    )}
                  {process.env.ENABLE_ORGANIZATION && !isVendor && (
                    <li>
                      <S.CreateOrgSeparator isTop />
                      <Link to={getUrls().createOrganizationUrl}>
                        <S.CreateOrg>
                          <S.Plus />
                          <S.CreateOrgText>
                            {formatMessage({ id: "create_organization" })}
                          </S.CreateOrgText>
                        </S.CreateOrg>
                      </Link>
                      <S.CreateOrgSeparator />
                    </li>
                  )}
                </>
              )}

            {process.env.ENABLE_ORGANIZATION && (
              <>
                <li>
                  <MenuLink
                    label={formatMessage({ id: "menu.my_profile" })}
                    url={urls.profileUrl}
                  />
                </li>
              </>
            )}
            <li>
              <MenuLink
                label={formatMessage({ id: "menu.support" })}
                url={urls.supportUrl}
              />
            </li>
            <li>
              <MenuLink
                label={formatMessage({ id: "menu.documentation" })}
                target="_blank"
                rel="noopener noreferrer"
                url={urls.docsUrl}
              />
            </li>

            {process.env.ENABLE_ORGANIZATION && !organization && !isVendor && (
              <li>
                <S.CreateOrgSeparator isTop />
                <Link to={getUrls().createOrganizationUrl}>
                  <S.CreateOrg>
                    <S.Plus />
                    <S.CreateOrgText>
                      {formatMessage({ id: "create_organization" })}
                    </S.CreateOrgText>
                  </S.CreateOrg>
                </Link>
                <S.CreateOrgSeparator />
              </li>
            )}
            <li>
              <MenuLink
                label={formatMessage({ id: "menu.logout" })}
                url={urls.logoutUrl}
              />
            </li>
          </ul>
        </MenuDropdownWrapper>
      </S.Layout>
    </>
  );
};

UserMenu.propTypes = {
  user: PropTypes.object,
  organizations: PropTypes.object,
  accountUrl: PropTypes.string,
  organizationId: PropTypes.string,
  breadcrumbs: PropTypes.node
};

export default UserMenu;
