import { FC } from "react";
import styled from "styled-components";

import Icon, { WrappingIconProps } from "../../Icon";

export const SuccessIconBackground = styled.path`
  fill: var(--icon-success, #000);
`;

export const SuccessIconTick = styled.path`
  fill: var(--icon-success-inverse, #fff);
`;

const SuccessIcon: FC<WrappingIconProps> = (props: WrappingIconProps) => (
  <Icon {...props} title="success">
    <SuccessIconBackground
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z"
    />
    <SuccessIconTick
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.69987 12.7003C5.31327 12.3137 5.31327 11.6869 5.69987 11.3003C6.08647 10.9137 6.71327 10.9137 7.09987 11.3003L9.99987 14.2003L16.8999 7.30028C17.2865 6.91368 17.9133 6.91368 18.2999 7.30028C18.6865 7.68688 18.6865 8.31368 18.2999 8.70028L10.707 16.2932C10.3164 16.6837 9.68328 16.6837 9.29276 16.2932L5.69987 12.7003Z"
    />
  </Icon>
);

export default SuccessIcon;
