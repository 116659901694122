import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import CustomScroll from "react-custom-scroll";
import { LiveMessage } from "react-aria-live";

import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { environmentsSelector } from "Reducers/environment";

import { getAllEnvironments, getTreeData } from "../utils";

import { MenuLinkItem } from "../";
import SearchInput from "Components/SearchInput";

import { getCSSVarString, INPUT } from "Libs/themes";
import * as S from "./Menu.styles";

const hasActiveChildren = env => {
  if (!env.children || env.children.length === 0) {
    return false;
  }
  if (env.children.some(child => child.status === "active")) {
    return true;
  }
  return hasActiveChildren(env.children);
};

const Menu = ({ closeDropDown }) => {
  const intl = useIntl();
  const { environmentId, organizationId } = useDecodedParams();

  const [titleFilter, setTitleFilter] = useState("");

  const environments = useSelectorWithUrlParams(environmentsSelector)
    ?.toArray()
    .map(environment => ({ ...environment.data }));

  const tree = useMemo(() => {
    if (!environments) return [];
    const rootEnv = environments.filter(environment => !environment.parent);
    return getTreeData(rootEnv, environments);
  }, [environments]);

  const list = useMemo(() => getAllEnvironments(tree), [tree]);

  const environmentFilter = env => {
    if (env.status === "inactive" && !hasActiveChildren(env)) {
      return false;
    }
    if (!env.title) return true;

    return env.title.toUpperCase().includes(titleFilter.toUpperCase());
  };

  if (!list || !list.length) {
    return false;
  }

  let environmentsList = tree;
  if (titleFilter !== "") {
    environmentsList = list.filter(environmentFilter).map(l => {
      return { ...l, children: [] };
    });
  }
  environmentsList = environmentsList.sort((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
    return 0;
  });

  const nbElements = environmentsList.reduce((acc, cur) => {
    return acc + cur.children.length;
  }, environmentsList.length);
  const moreThanTen = nbElements > 10;

  return (
    <S.Wrapper className="environments-menu">
      <S.Content role="navigation">
        {titleFilter && (
          <LiveMessage
            message={`environment list results filtered by ${titleFilter}. ${environmentsList.length} environments found.`}
            aria-live="polite"
          />
        )}
        <SearchInput
          inputColor={"var(--input-grey-light-color,var(--input-grey-light,var(--grey-light)))"}
          iconSize={14}
          placeholder={intl.formatMessage({ id: "environment.search" })}
          onChange={e => setTitleFilter(e.target.value)}
          value={titleFilter}
          className="search"
          id="environment-search"
        />
        <S.Environments
          className="environments-links"
          role="menu"
          aaria-label="environments"
        >
          {list.length === 0 ? (
            <S.Empty>
              {intl.formatMessage({
                id: "environmentTree.menu.empty",
                defaultMessage: "No results."
              })}
            </S.Empty>
          ) : (
            <ListWrapper scrollable={moreThanTen}>
              <ol>
                {environmentsList.map(node => {
                  return (
                    <MenuLinkItem
                      id="environment-branch"
                      node={node}
                      organizationId={organizationId}
                      currentEnvironment={environmentId}
                      key={node.environmentId}
                      searchValue={titleFilter}
                      filter={environmentFilter}
                      closeDropDown={closeDropDown}
                    />
                  );
                })}
              </ol>
            </ListWrapper>
          )}
        </S.Environments>
      </S.Content>
    </S.Wrapper>
  );
};

const ListWrapper = ({ children, scrollable }) =>
  scrollable ? (
    <CustomScroll heightRelativeToParent="332px">{children}</CustomScroll>
  ) : (
    <S.NoScroll>{children}</S.NoScroll>
  );

Menu.propTypes = {
  closeDropDown: PropTypes.func
};

ListWrapper.propTypes = {
  children: PropTypes.node,
  scrollable: PropTypes.bool
};

export default Menu;
