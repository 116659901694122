import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { checkGoLive, getSubscriptionEditUrl } from "Libs/utils";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { Link } from "ds/Button";

import * as S from "./LiveButton.styles";

const LiveButton = ({
  className = "",
  organizationId,
  project,
  subscription
}) => {
  const intl = useIntl();

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription }),
    [project, subscription]
  );

  const getButton = () => {
    if (!project.subscription?.plan) return false;
    const goLive = checkGoLive(project, organization);

    switch (goLive) {
      case "live":
        return (
          <S.Live>
            {intl.formatMessage({
              id: "project.state.live",
              defaultMessage: "Live"
            })}
          </S.Live>
        );
      case "development":
        if (!subscriptionEditUrl) return;
        return (
          <Link
            variant="primary"
            to={{
              pathname: subscriptionEditUrl.url,
              state: { from: "project" }
            }}
            target={subscriptionEditUrl.external ? "_blank" : null}
          >
            {intl.formatMessage({
              id: "project.screenshot.state.not_live",
              defaultMessage: "Go live"
            })}
          </Link>
        );
      case "no-permission":
        return false;
      default:
        return (
          <Link
            variant="primary"
            to={`/${organizationId}/${project.id}/-/settings/domains`}
          >
            {intl.formatMessage({
              id: "project.state.not_live",
              defaultMessage: "Go live"
            })}
          </Link>
        );
    }
  };

  return <S.Wrapper className={className}>{getButton()}</S.Wrapper>;
};

LiveButton.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object,
  subscription: PropTypes.object,
  organizationId: PropTypes.string
};

export default LiveButton;
