import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import { css, SECTION } from "Libs/themes";

import ModalWrapper from "../Modal";
import { Button, ButtonWrapper } from "ds/Button";
import { capitalize } from "Libs/utils";

const ModalBody = styled.div`
  margin-bottom: 48px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

class ModalConfirmDelete extends React.Component {
  constructor(props) {
    super(props);
    this.modalClassOverride = this.modalClassOverride.bind(this);
    this.overlayClassOverride = this.overlayClassOverride.bind(this);

    this.state = {
      modalCustomClass: "modal-after-open",
      overlayCustomClass: "overlay-after-open"
    };
  }

  modalClassOverride(modalClass) {
    if (modalClass) {
      this.setState({
        modalCustomClass: modalClass + " " + this.state.modalCustomClass
      });
    }
    return this.state.modalCustomClass;
  }

  overlayClassOverride(overlayClass) {
    if (overlayClass) {
      this.setState({
        overlayCustomClass: overlayClass + " " + this.state.overlayCustomClass
      });
    }
    return this.state.overlayCustomClass;
  }

  render() {
    const {
      title = "Are you sure you want to delete this?",
      closeModal,
      cancelFunction,
      isOpen,
      deleteFunction,
      itemType,
      itemName,
      itemId,
      intl,
      body,
      deleteText,
      modalSize = "small",
      deleteButton = true,
      cancelButton = true,
      cancelText,
      className,
      id
    } = this.props;
    return (
      <ModalWrapper
        modalClass={`modal-${modalSize}${className ? " " + className : ""}`}
        isOpen={isOpen}
        closeModal={closeModal}
        title={title}
        id={id}
      >
        <ModalBody className="modal-body">
          {body ? (
            body
          ) : (
            <span>
              Yes, delete the {itemType} <strong>{itemName}</strong>.
            </span>
          )}
        </ModalBody>
        <ButtonWrapper hasSpacing justifyContent="end">
          {deleteButton && (
            <Button
              id={`delete-${itemId}-confirm-modal-button`}
              type="submit"
              aria-label={capitalize(
                deleteText ? deleteText : intl.formatMessage({ id: "delete" })
              )}
              onClick={e => {
                deleteFunction(e);
                closeModal();
              }}
            >
              {capitalize(
                deleteText ? deleteText : intl.formatMessage({ id: "delete" })
              )}
            </Button>
          )}
          {cancelButton && (
            <Button
              variant="secondary"
              aria-label={intl.formatMessage({ id: "cancel" })}
              onClick={() => (cancelFunction || closeModal)()}
            >
              {capitalize(
                cancelText ? cancelText : intl.formatMessage({ id: "cancel" })
              )}
            </Button>
          )}
        </ButtonWrapper>
      </ModalWrapper>
    );
  }
}

ModalConfirmDelete.defaultProps = {
  closeModal: () => {}
};

ModalConfirmDelete.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  cancelFunction: PropTypes.func,
  deleteFunction: PropTypes.func,
  itemType: PropTypes.string,
  itemName: PropTypes.string,
  itemId: PropTypes.string,
  intl: PropTypes.object,
  body: PropTypes.node,
  deleteText: PropTypes.string,
  cancelText: PropTypes.string,
  modalSize: PropTypes.string,
  deleteButton: PropTypes.bool,
  cancelButton: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string
};

export default injectIntl(ModalConfirmDelete);
