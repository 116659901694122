import styled, { css as styledCSS } from "styled-components";
import { getCSSVarString, INPUT, css, SECTION } from "Libs/themes";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as LabelPrimitive from "@radix-ui/react-label";

export const Root = styled(CheckboxPrimitive.Root)<{ isValid?: boolean }>`
  height: 18px;
  min-width: 18px;
  max-width: 18px;
  border-radius: 2px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color:var(--input-granite-border-color,var(--input-granite,var(--granite)));
  opacity: 1;
  box-shadow: none;
  position: relative;
  cursor: pointer;
  outline: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  :focus,
  :active {
    border-color:var(--input-skye-border-color,var(--input-skye,var(--skye)));
    box-shadow: 0px 0px 2px
        var(--input-skye-dark-box-shadow-color,var(--input-skye-dark,var(--skye-dark))),
      inset 0 0 2px var(--input-skye-dark-box-shadow-color,var(--input-skye-dark,var(--skye-dark)));
  }

  :disabled {
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[data-state="checked"] {
    border-color:var(--input-skye-border-color,var(--input-skye,var(--skye)));
  }

  ${({ isValid }) =>
    !isValid &&
    styledCSS`
      border-color:var(--input-red-border-color,var(--input-red,var(--red)));

      &[data-state="checked"] {
        border-color:var(--input-red-border-color,var(--input-red,var(--red)));
      }
    `};
`;

interface LabelProps {
  disabled?: boolean;
}

export const Label = styled(LabelPrimitive.Root)<LabelProps>`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: fit-content;
  line-height: 24px;
  color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
  font-weight: 400;
  font-size: 14px;

  ${({ disabled }) =>
    disabled &&
    styledCSS`
      cursor: not-allowed;
      opacity: 0.5;
  `};
`;

export const Indicator = styled(CheckboxPrimitive.Indicator)`
  display: flex;
  height: 10px;
  width: 10px;
`;

export const Layout = styled.div`
  margin-bottom: 8px;
  display: flex;
  outline: none;
  align-items: center;
  gap: 12px;
`;

export const Error = styled.div`
  font-weight: 400;
  color:var(--section-red-color,var(--section-red,var(--red)));
  font-size: 13px;

  &:first-letter {
    text-transform: uppercase;
  }
`;
