import styled from "styled-components";
import { css, HEADER } from "Libs/themes";

export default styled.h6`
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  margin: 0 0 16px;
  &:first-letter {
    text-transform: uppercase;
  }
`;
