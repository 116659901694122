import styled from "styled-components";

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  z-index: 1;
  justify-content: flex-start;
  gap: 8px;
`;

export const Wrapper = styled.div`
  #environment-actions-dropdown-window {
    width: max-content;
    .tooltip-content-wrapper {
      padding-top: 11px;
      padding-bottom: 12px;
    }
  }
`;
