import ChevronIcon from "Components/icons/ChevronIcon";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import Back from "Icons/Back";
import { getUrls } from "Libs/utils";

import * as S from "./styles";

const SPACE_KEY = 32;
const RETURN_KEY_CODE = 13;

const OrganizationMobileMenu = props => {
  const { onBack, organizations, selected } = props;
  const formatMessage = useIntl().formatMessage;

  const isVendor = process.env.VENDOR_NAME || process.env.VENDOR_URL;

  return (
    <S.OrganizationMenuWrapper>
      <S.List
        area-label="Return to Menu"
        className="organization-menu-back"
        role="button"
        tabIndex="0"
        onKeyDown={event =>
          (event.keyCode === SPACE_KEY || event.keyCode === RETURN_KEY_CODE) &&
          onBack()
        }
        onClick={onBack}
      >
        <Back />
        <span className="organization-back_text">
          <FormattedMessage id="back" />
        </span>
      </S.List>
      <S.List>
        <S.OrganizationSwitcher className="orgainzation-switcher">
          <div className="organization_switch_title">
            <FormattedMessage id="menu.select_organization" />
          </div>
        </S.OrganizationSwitcher>
      </S.List>

      <S.List active={window.location.pathname === "/"}>
        <S.Link url="/">
          <S.OrganizationSwitcher className="orgainzation-switcher">
            <div className="orgainzation-name">
              <span>
                <FormattedMessage id="menu.all_organization_project" />
              </span>
            </div>
            <ChevronIcon className="organization_switch_name_icon" />
          </S.OrganizationSwitcher>
        </S.Link>
      </S.List>
      {organizations.map(org => (
        <S.List key={org.label} active={org?.id === selected?.id}>
          <S.Link url={`/${org.name}`}>
            <S.OrganizationSwitcher className="orgainzation-switcher">
              <div className="orgainzation-name">{org.label}</div>
              <ChevronIcon className="organization_switch_name_icon" />
            </S.OrganizationSwitcher>
          </S.Link>
        </S.List>
      ))}
      {!isVendor && (
        <S.AddButton id="create-org-link" to={getUrls().createOrganizationUrl}>
          {formatMessage({ id: "create_organization" })}
        </S.AddButton>
      )}
    </S.OrganizationMenuWrapper>
  );
};

OrganizationMobileMenu.propTypes = {
  onBack: PropTypes.func,
  selected: PropTypes.object,
  organizations: PropTypes.array
};

export default OrganizationMobileMenu;
