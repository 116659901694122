const debounce = (fn: (...args: []) => unknown) => {
  let timer: number;

  return (...args: []) => {
    if (timer) {
      window.cancelAnimationFrame(timer);
    }

    timer = window.requestAnimationFrame(() => fn.apply(null, args));
  };
};

export default debounce;
