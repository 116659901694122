import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import copyToClipboard from "copy-to-clipboard";
import PropTypes from "prop-types";

import CopyIcon from "Icons/CopyIcon";
import SuccessStateIcon from "Icons/SuccessStateIcon";

import * as S from "./CopyButton.styles";

const CopyButton = ({
  className = "",
  icon,
  text,
  title,
  variant = "primary",
  onClick,
  onCopy,
  withText = true
}) => {
  const intl = useIntl();

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [copied]);

  const copy = link => {
    onCopy ? onCopy(link) : copyToClipboard(link);
    setCopied(true);
  };

  return (
    <S.Button
      className={`copy-to-clipboard ${className}`}
      variant={variant}
      content="mixed"
      onClick={e => {
        text && copy(text);
        document.activeElement.blur();
        onClick?.(e);
      }}
      aria-label={title ? title : intl.formatMessage({ id: "icons.copy" })}
    >
      {copied && <SuccessStateIcon />}
      {copied ? null : icon ? icon : <CopyIcon aria-hidden="true" />}
      {withText && (
        <S.Text className="copy-button-text">
          <S.Copy copied={copied}>
            {title ? title : intl.formatMessage({ id: "copy" })}
          </S.Copy>
          {copied && (
            <S.Copied>{intl.formatMessage({ id: "copied" })}</S.Copied>
          )}
        </S.Text>
      )}
    </S.Button>
  );
};

CopyButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  onCopy: PropTypes.func,
  withText: PropTypes.bool
};

export default CopyButton;
