import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { getCSSVarString, ICON } from "Libs/themes";

import IconLayoutTree from "Components/icons/IconLayoutTree";
import IconLayoutList2 from "Components/icons/IconLayoutList2";
import * as S from "./ToggleTree.styles";

const ToggleTree = ({ toggleLayout, gridLayout }) => {
  const intl = useIntl();

  return (
    <S.ToggleWrapper>
      <button
        className={`toggle-button ${gridLayout ? "active" : ""}`}
        onClick={() => {
          toggleLayout(true);
          document.activeElement.blur();
        }}
        aria-label={intl.formatMessage({
          id: "environments.tree_layout"
        })}
      >
        <IconLayoutTree color={"var(--icon-ebony-light-color,var(--icon-ebony-light,var(--ebony-light)))"} />
      </button>
      <button
        className={`toggle-button ${!gridLayout ? "active" : ""}`}
        onClick={() => {
          toggleLayout(false);
          document.activeElement.blur();
        }}
        aria-label={intl.formatMessage({
          id: "environments.list_layout"
        })}
      >
        <IconLayoutList2
          color={"var(--icon-ebony-light-color,var(--icon-ebony-light,var(--ebony-light)))"}
        />
      </button>
    </S.ToggleWrapper>
  );
};

ToggleTree.propTypes = {
  toggleLayout: PropTypes.func,
  gridLayout: PropTypes.bool
};

export default ToggleTree;
