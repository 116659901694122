import moment from "moment";

export const getAllEnvironments = tree => {
  if (!tree) return [];

  return tree.reduce((acc, current) => {
    if (!current) return acc;
    return acc.concat(current, getAllEnvironments(current.children));
  }, []);
};

export const getTreeData = (nodes, environments, depth = 0) => {
  const environmentKeys = environments.map(env => env.id);

  return nodes.map(node => {
    let orphanKeys = [];
    const orphanedEnvs = environments.filter(
      env => env.parent && !environmentKeys.includes(env.parent)
    );

    orphanedEnvs.forEach(env => {
      env.parent = node.id;
      orphanKeys.push(env.id);
    });

    const newNodes = environments.filter(
      environment => environment.parent === node.id
    );

    let children = [];

    if (newNodes.length) {
      children = getTreeData(newNodes, environments, depth + 1);
    }

    if (orphanedEnvs.length) {
      children = children.map(child => {
        if (orphanKeys.includes(child.environmentId)) {
          child.stub = true;
        }
        return child;
      });
    }

    return {
      environmentId: node.id,
      projectId: node.project,
      created_at: node.created_at,
      updated_at: node.updated_at,
      title: node.title,
      type: node.type,
      status: node.status,
      head_commit: node.head_commit,
      deployment_state: node.deployment_state,
      deployed_at:
        node.deployment_state?.last_deployment_at || moment().format(),
      deployment_target: node.deployment_target,
      isRoot: !node.parent,
      depth,
      children
    };
  });
};
