import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Map } from "immutable";
import { gitProjectSelector } from "Reducers/project";
import { setIsLoadingState } from "Reducers/utils";

export const loadProjectCapability = createAsyncThunk(
  "app/project/capability/load",
  async ({ organizationId, projectId }, { getState }) => {
    const project = gitProjectSelector(getState(), {
      organizationId,
      projectId
    });
    const capabilities = await project.getCapabilities();
    return { capabilities, projectId, organizationId };
  }
);

const projectCapabilities = createSlice({
  name: "capabilities",
  initialState: Map(),
  extraReducers: {
    [loadProjectCapability.pending]: (state, { meta }) => {
      const { projectId, organizationId } = meta.arg;
      return setIsLoadingState(state, [organizationId, projectId], true);
    },
    [loadProjectCapability.fulfilled]: (state, { payload }) => {
      const { capabilities, projectId, organizationId } = payload;
      return setIsLoadingState(state, [organizationId, projectId], false).setIn(
        [organizationId, projectId],
        capabilities
      );
    },
    [loadProjectCapability.rejected]: (state, { meta }) => {
      const { projectId, organizationId } = meta.arg;
      return setIsLoadingState(state, [organizationId, projectId], false);
    }
  }
});

export const projectCapabilitySelector = (
  state,
  { organizationId, projectId }
) => state.capabilities?.getIn([organizationId, projectId]);

export default projectCapabilities.reducer;
