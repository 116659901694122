const colorNames = {
  darksnow: "#ffffff",
  ice: "#f5f7fa",
  grey: "#f0f2f5",
  stone: "#e7e7e7",
  periwinkleGrey: "#c9d0e4",
  granite: "#98a0ab",
  slate: "#5f5e70",
  ebonyLight: "#4a495e",
  ebony: "#302f45",
  night: "#1a192b",
  skyeLight: "#e7f0ff",
  lighterSky: "#5792FF",
  skye: "#4786ff",
  skyeDark: "#1664ff",
  gold: "#eda900",
  java: "#23b7b7",
  fire: "#ff8359",
  red: "#fb3728",
  ocean: "#4c62a3",
  purple: "#9d67ff",
  wine: "#6d144f",
  pink: "#ffbdbb",
  lightPink: "#ffeeee",
  darkPink: "#ff9f9f",
  hotPink: "#e04aa5",
  beige: "#f6f6f1",
  aqua: "#7bd8db",
  candyGreen: "#289b3f",
  wacgRed: "#e50000",
  lemon: "#fff9c4",
  oldGold: "#9a6f1e",
  light: "#86B5FF"
};

// This is needed by the useTheme hook
export const palette = colorNames;
