import styled from "styled-components";
import { css, LINK, SECTION, MENU, ICON } from "Libs/themes";
import PlusIcon from "Icons/PlusIcon";

export const Layout = styled.div`
  display: inline-block;
  position: relative;
  display: none;

  @media (min-width: 765px) {
    display: inline-block;
  }
  button {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    background: transparent;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: inherit;
    padding: 0 35px 0 8px;
    white-space: nowrap;
    cursor: pointer;
    img {
      max-width: 24px;
      max-height: 24px;
      border-radius: 2px;
      overflow: hidden;
    }
    span.chevron {
      position: absolute;
      right: 3px;
      top: 6px;
      height: 24px;
      width: 24px;
    }
    &.picture {
      padding: 0 35px 0 3px;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 4px;
      outline: none;
    }
  }
  a {
    text-decoration: none;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    font-size: 15px;
  }
  .menu-dropdown {
    background-color:var(--menu-snow-background-color,var(--menu-snow,var(--snow)));
    padding: 8px;
    width: 214px;
    min-width: 214px;
    box-sizing: border-box;
    left: auto;
    right: 0;
    overflow: hidden;
    margin-top: 8px;
    a {
      &:hover,
      &:focus,
      &:active {
        background-color:var(--link-skye-light-background-color,var(--link-skye-light,var(--skye-light)));
        border: ${props =>
          props.theme.name === "contrast" ? "none" : "inherit"};
        box-shadow: ${props =>
          props.theme.name === "contrast" ? "none" : "inherit"};
        margin: ${props => (props.theme.name === "contrast" ? "0" : "inherit")};
        position: relative;
        span.active {
          position: absolute;
          top: 14px;
          left: 4px;
          display: ${props =>
            props.theme.name === "contrast" ? "inline-block" : "none"};
          height: 4px;
          width: 4px;
          border-radius: 4px;
          background-color:var(--link-skye-background-color,var(--link-skye,var(--skye)));
        }
      }
    }
  }
`;

export const OrganizationLabel = styled.div`
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
`;

export const Separator = styled.hr`
  width: calc(100% + 16px);
  margin-left: -8px;
  overflow: hidden;
  background:var(--section-periwinkle-grey-background,var(--section-periwinkle-grey,var(--periwinkle-grey)));
`;

export const CreateOrgSeparator = styled.hr`
  background:var(--section-periwinkle-grey-background,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  ${({ isTop }) => (isTop ? "auto" : "width: calc(100% + 16px)")};
  ${({ isTop }) => (isTop ? "auto" : "margin-left: -8px")};
  overflow: hidden;
  ${({ isTop }) => (isTop ? "margin-bottom:8px" : "margin-top:8px")};
`;

export const CreateOrg = styled.div`
  &:hover,
  &:focus,
  &:active {
    background-color:var(--link-skye-light-background-color,var(--link-skye-light,var(--skye-light)));
  }
  display: flex;
  align-items: center;
`;

export const Plus = styled(PlusIcon)`
  g {
    fill:var(--icon-skye-fill,var(--icon-skye,var(--skye)));
  }
  padding-right: 8px;
`;

export const CreateOrgText = styled.span`
  color:var(--section-skye-color,var(--section-skye,var(--skye))) !important;
  font-size: 15px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
`;
