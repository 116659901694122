import styled, { css as styledCSS } from "styled-components";
import { css, ICON, SECTION } from "Libs/themes";

import * as EnvListBar from "../EnvironmentListBar/EnvironmentListBar.styles";

export const Children = styled.div`
  padding: 0 0 0 48px;
`;

export const NodeLayout = styled.div`
  position: relative;
  line-height: 32px;

  & + & > ${EnvListBar.Wrapper} {
    &:after {
      top: -32px;
      height: 48px;
    }
  }

  > ${EnvListBar.Wrapper} {
    margin-bottom: 16px;
  }

  ${({ hasChildren, isRoot }) =>
    !isRoot &&
    hasChildren &&
    styledCSS`
    > ${EnvListBar.Wrapper} ${EnvListBar.IconWrapper} {
      background-color:var(--icon-skye-background-color,var(--icon-skye,var(--skye)));
      > svg path {
        fill:var(--has-children-environment-icon-fill,var(--icon-snow-fill,var(--icon-snow,var(--snow))));
      }
    }
  `}

  ${({ hasChildren, isRoot, status }) =>
    !isRoot &&
    hasChildren &&
    status === "inactive" &&
    styledCSS`
    > ${EnvListBar.Wrapper} ${EnvListBar.IconWrapper} {
      background-color:var(--icon-grey-background-color,var(--icon-grey,var(--grey)));
      > svg path {
        fill:var(--has-children-environment-icon-fill,var(--icon-granite-fill,var(--icon-granite,var(--granite))));
      }
    }
  `}

  ${({ depth }) =>
    depth > 1 &&
    styledCSS`
      ${Children} {
        padding-left: 41px;
      }
      ${EnvListBar.IconWrapper} {
        min-width: 24px;
        width: 24px;
        height: 24px;
      }
    `}

  ${({ isRoot }) =>
    !isRoot &&
    styledCSS`
      &:before {
        position: absolute;
        content: "";
        background-color:var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
        height: 1px;
        top: 16px;
        left: -${({ depth }) => (depth <= 2 ? 32 : 29)}px;
        width: ${({ depth }) => (depth <= 2 ? 32 : 29)}px;
      }

      &:after {
        position: absolute;
        content: "";
        background-color:var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
        width: 1px;
        left: -${({ depth }) => (depth <= 2 ? 32 : 29)}px;
        top: -16px;
        bottom: 0;
        height: calc(100% + 16px);
      }

      &:last-child:after {
        top: -16px;
        height: 32px;
      }
    `}
`;
