import { fromJS, Map } from "immutable";

import client, { entities } from "Libs/platform";
import logger from "Libs/logger";

import { setThemeFromProfile } from "Reducers/app/theme";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getIsLoadingState, { setIsLoadingState } from "Reducers/utils";

import {
  GetMapStoreStateType,
  StoreMapStateType,
  MapStoreStateType,
  DispatchActionType
} from "Reducers/types";

const REQUEST_EMAIL_RESET_START = "app/profile/request_email_reset_start";
const REQUEST_EMAIL_RESET_SUCCESS = "app/profile/request_email_reset_success";
const REQUEST_EMAIL_RESET_FAILURE = "app/profile/request_email_reset_failure";
const randomStr = () => Math.random().toString(36).substring(7);

const modifiableFields = {
  auth: [
    "first_name",
    "last_name",
    "username",
    "picture",
    "company",
    "website"
  ],
  accounts: [
    "display_name",
    "username",
    "picture",
    "company_type",
    "company_role",
    "company_name",
    "website_url",
    "new_ui",
    "ui_colorscheme",
    "ui_contrast",
    "default_catalog",
    "marketing"
  ]
};

const getUserNameById = (id: string, state: MapStoreStateType) =>
  Object.entries<{ id: string }>(state.profile.get("data").toJS()).find(
    ([, data]) => data.id === id
  )?.[0];

export const loadCurrentUserProfile = createAsyncThunk(
  "app/profile/load-current-user-profile",
  async (_, { dispatch, rejectWithValue, getState }) => {
    const state = getState() as MapStoreStateType;
    const userId = state.app.getIn(["me", "id"]);
    try {
      dispatch(loadUserProfile(userId));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

type LoadUserProfileThunkReturnType = {
  profile: Record<string, any>;
};

export const loadUserProfile = createAsyncThunk<
  LoadUserProfileThunkReturnType,
  string
>("app/profile/load", async (id, { rejectWithValue, dispatch }) => {
  try {
    const [accountsProfile, authUser] = await Promise.all([
      client.getUserProfile(id),
      client.getUser(id)
    ]);

    const profile = {
      ...accountsProfile,
      ...authUser
    };

    if (accountsProfile?.picture) {
      profile.picture = accountsProfile.picture;
    }
    dispatch(setThemeFromProfile(profile));
    return {
      profile
    };
  } catch (error) {
    if (error instanceof Error)
      logger(error, {
        action: "LOAD_PROFILE_FAILURE"
      });
    return rejectWithValue(error);
  }
});

type UpdateProfileThunkArgType = {
  id: string;
  data: {
    email?: string;
    picture?: File | null;
    username?: string;
    marketing?: boolean;
  };
};

export const updateUserProfile = createAsyncThunk<
  Record<string, any>,
  UpdateProfileThunkArgType
>(
  "app/profile/update",
  async ({ id, data }, { rejectWithValue, dispatch, getState }) => {
    const { AccountsProfile, AuthUser } = entities;
    const state = getState() as MapStoreStateType;
    const username = getUserNameById(id, state);
    try {
      if (data.email) {
        dispatch(updateUserEmail({ id, emailAddress: data.email }));
      }
      const authFields = modifiableFields.auth;
      const accountsFields = modifiableFields.accounts.filter(
        // don't save field already saved to auth api
        (field: string) => !authFields.includes(field)
      );

      const picture = data.picture;
      delete data.picture;

      const updatesForAuthUser = Object.entries(data).reduce(
        (fields, [key, value]) => {
          if (value && authFields.includes(key)) {
            fields[key] = value;
          }
          return fields;
        },
        {} as Record<string, Object>
      );
      let updatesForAccountsProfile = Object.entries(data).reduce(
        (fields, [key, value]) => {
          if (value && accountsFields.includes(key)) {
            fields[key] = value;
          }
          return fields;
        },
        {} as Record<string, Object>
      );

      if (picture === null) {
        await client.deleteProfilePicture(id);
      } else if (picture) {
        const formData = new FormData();
        formData.append("file", picture);
        try {
          const { url } = await client.updateProfilePicture(id, formData);
          if (url && !Object.keys(updatesForAccountsProfile).length) {
            return {
              username,
              url,
              type: "picture"
            };
          }
        } catch (error) {
          const rejection = {
            message: "",
            size: picture.size,
            name: picture.name
          };
          if (error instanceof Error) rejection.message = error.message;
          return rejectWithValue(rejection);
        }
      }

      const previousProfile = state.profile.getIn(["data", username]).toJS();
      let accountsProfile;
      let authUser;

      if (Object.keys(updatesForAccountsProfile).length > 0) {
        accountsProfile = await AccountsProfile.update(
          id,
          updatesForAccountsProfile
        );
      }

      if (Object.keys(updatesForAuthUser).length > 0) {
        // TODO fix in client-js, should use updatable fields for
        // AuthUser as opposed to the AuthUser instance which is
        // supposed to be read only
        // Should be easy to fix once we have
        // https://lab.plat.farm/accounts/console/-/issues/462
        //@ts-ignore
        authUser = await AuthUser.update(id, updatesForAuthUser);
      }

      const profile = {
        ...previousProfile,
        ...accountsProfile,
        ...authUser
      };

      if (accountsProfile?.picture) {
        profile.picture = accountsProfile.picture;
      }
      dispatch(setThemeFromProfile(profile));
      if (Object.keys(profile).length > 0) {
        return {
          profile
        };
      }
    } catch (error: any) {
      let parsedError = error;
      try {
        parsedError = JSON.parse(parsedError);
      } catch (e) {
        parsedError = error;
      }
      // Skips logging for validation errors
      if (parsedError?.title !== "Bad Request") {
        logger(error, {
          action: "UPDATE_PROFILE_FAILURE"
        });
      }
      return rejectWithValue({
        error: true,
        payload: error
      });
    }
  }
);

export const deleteProfilePicture = () => {
  return async (
    dispatch: DispatchActionType,
    getState: GetMapStoreStateType
  ) => {
    const uuid = getState().app.getIn(["me", "uuid"]);
    try {
      await client.deleteProfilePicture(uuid);
      dispatch(loadCurrentUserProfile());
    } catch (error) {
      logger(error as Error, { action: "DELETE_USER_PROFILE_FAILED" });
    }
  };
};

type UpdateUserEmailThunkArgType = { id: string; emailAddress: string };
type UpdateUserEmailThunkReturnType = { emailAddress: string };

const updateUserEmail = createAsyncThunk<
  UpdateUserEmailThunkReturnType,
  UpdateUserEmailThunkArgType
>(
  "app/profile/update-user-email",
  async ({ id, emailAddress }, { rejectWithValue, dispatch }) => {
    dispatch({ type: REQUEST_EMAIL_RESET_START });
    const { AuthUser } = entities;
    try {
      await AuthUser.updateEmailAddress(id, emailAddress);
      dispatch({
        type: REQUEST_EMAIL_RESET_SUCCESS,
        payload: { emailAddress }
      });
      return { emailAddress };
    } catch (error) {
      logger(error as Error, { action: "REQUEST_EMAIL_RESET_FAILURE" });
      dispatch({ type: REQUEST_EMAIL_RESET_FAILURE, payload: error });
      return rejectWithValue({ emailAddress, error });
    }
  }
);

const profileReducer = createSlice({
  name: "profile",
  initialState: Map({ data: Map() }) as StoreMapStateType,
  reducers: {
    clearErrors: state => state.remove("errors"),
    pictureError: (state, action) => state.set("error", action.payload)
  },
  extraReducers: builder => {
    //Load Profile
    builder
      .addCase(loadUserProfile.pending, state =>
        setIsLoadingState(state, "load")
      )
      .addCase(loadUserProfile.fulfilled, (state, action) => {
        const { profile } = action.payload;
        return setIsLoadingState(state, "load", false)
          .setIn(["data", profile.username], fromJS(profile))
          .setIn(["cacheKeys", profile.username], randomStr());
      })
      .addCase(loadUserProfile.rejected, (state, action) => {
        return setIsLoadingState(state, "load", false).set(
          "errors",
          action.payload
        );
      });

    //Update Profile
    builder
      .addCase(updateUserProfile.pending, state =>
        setIsLoadingState(state, "update").set("loading", true)
      )
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        const { type, url, username, profile } = action.payload || {};

        if (type === "picture") {
          return setIsLoadingState(state, "update", false).updateIn(
            ["data", username],
            (e: StoreMapStateType) =>
              e.mergeDeep({
                picture: url
              })
          );
        }
        return setIsLoadingState(state, "update", false)
          .setIn(["data", profile.username], fromJS(profile))
          .setIn(["cacheKeys", profile.username], randomStr());
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        return setIsLoadingState(state, "update", false).set(
          "error",
          action.payload
        );
      });

    //update user email
    builder.addCase(updateUserEmail.fulfilled, (state, action) =>
      state.set("emailReset", action.payload.emailAddress)
    );

    // Load current User
    builder
      .addCase(loadCurrentUserProfile.rejected, state =>
        state.set("loading", false)
      )
      .addCase(loadCurrentUserProfile.pending, state =>
        state.set("loading", true)
      )
      .addCase(loadCurrentUserProfile.fulfilled, state =>
        state.set("loading", false)
      );
  }
});

export default profileReducer.reducer;
export const { clearErrors, pictureError } = profileReducer.actions;

export const getIsUpdatingProfileSelector = (state: MapStoreStateType) =>
  getIsLoadingState(state.profile, "update");

export const getIsLoadingProfileSelector = (state: MapStoreStateType) =>
  getIsLoadingState(state.profile, "load");
