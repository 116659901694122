import React from "react";
import PropTypes from "prop-types";
import logger from "Libs/logger";

class UserWidget extends React.PureComponent {
  async componentDidMount() {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const auth = await client.getAccessToken();
    const access_token = auth.access_token;

    const userWidget = await import(
      "platformsh-user-widget/dist/without-psh-client"
    );
    const userWidgetInit = userWidget.default;

    userWidgetInit(document.getElementById("user-widget"), {
      source: this.props.source,
      getQueryString: this.props.getQueryString,
      accountUrl: this.props.accountUrl,
      nameOrAvatar: this.props.nameOrAvatar,
      showProjectsMenu: this.props.showProjectsMenu,
      adminOnly: true,
      access_token
    });
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.getQueryString().projectId !==
      prevProps.getQueryString().projectId
    ) {
      const { updateContext } = await import(
        "platformsh-user-widget/dist/without-psh-client"
      );
      updateContext(this.props);
    }
  }

  componentDidCatch(error, info) {
    logger(error);
    logger(info);
  }

  render() {
    return <div id="user-widget" />;
  }
}

UserWidget.propTypes = {
  source: PropTypes.string,
  getQueryString: PropTypes.func,
  accountUrl: PropTypes.string,
  nameOrAvatar: PropTypes.bool,
  showProjectsMenu: PropTypes.bool
};

export default UserWidget;
