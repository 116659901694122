import styled from "styled-components";

import { css, SECTION, getCSSVarString, TOOLTIP } from "Libs/themes";

export const Main = styled.main`
  display: inline-block;
  min-height: calc(100vh - 98px - 63px);
  width: 100%;
  padding: 0 24px;
  color:var(--section-night-color,var(--section-night,var(--night)));
  &.project {
    min-height: calc(100vh - 120px - 63px);
  }
  .type-dark,
  &.theme-dark .step-wrapper {
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? `var(--tooltip-box-shadow,var(--box-shadow))
      var(--tooltip-black-box-shadow-color,var(--tooltip-black,var(--black)))`
        : "none"};
  }
  &.theme-dark .step-wrapper {
    border-radius: 4px;
  }
  @media (min-width: 768px) {
    padding: 0 40px;
    &.create-project {
      padding-top: 0;
    }
  }
  &.billing-details {
    @media (min-width: 768px) {
      padding: 0 40px;
    }
    @media (min-width: 1920px) {
      padding: 0 60px;
    }
  }
`;
