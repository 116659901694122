import { FC } from "react";
import Icon, { WrappingIconProps } from "../../Icon";

const ScrollDownIcon: FC<WrappingIconProps> = (props: WrappingIconProps) => (
  <Icon {...props} title="ScrollDown">
    <path d="M8.8371 9.3875L11.1246 11.675L11.1246 3.875C11.1246 3.39175 11.5164 3 11.9996 3C12.4829 3 12.8746 3.39175 12.8746 3.875L12.8746 11.675L15.1621 9.3875C15.5004 9.04923 16.0488 9.04923 16.3871 9.3875C16.7254 9.72577 16.7254 10.2742 16.3871 10.6125L12.7067 14.2929C12.3162 14.6834 11.683 14.6834 11.2925 14.2929L7.6121 10.6125C7.27383 10.2742 7.27383 9.72577 7.6121 9.3875C7.95038 9.04923 8.49883 9.04923 8.8371 9.3875ZM11.9996 21C13.1042 21 13.9996 20.1046 13.9996 19C13.9996 17.8954 13.1042 17 11.9996 17C10.895 17 9.9996 17.8954 9.9996 19C9.9996 20.1046 10.895 21 11.9996 21Z" />
  </Icon>
);

export default ScrollDownIcon;
