import React, { useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import NavigationMenu from "Containers/NavBar/NavigationMenu";
import BranchIcon from "Icons/BranchIcon";
import MergeIcon from "Icons/MergeIcon";
import SyncIcon from "Icons/SyncIcon";

import { ActionButton } from "ds/Button";
import EnvironmentBackupModal from "./BackupModal";
import FloatBox from "Containers/NavBar/FloatBox";

import { capitalize, getEnvironmentURI } from "Libs/utils";
import { breakpoints } from "Libs/theme";

import * as S from "./Environment.styles";
import MoreEnvironmentAction, {
  MORE_ACTION_ID
} from "./moreActions/MoreEnvironmentAction";
import { SourceOperations } from "./sourceOperations";

// this controls when to show the text in the action buttons
// for this to work the text must be wrapped in a <span> tag
const showCompleteButtonsMQ = `(min-width: ${breakpoints[2]})`;

const EnvironmentNavBar = ({
  project,
  environment,
  organizationId,
  projectId,
  environmentId,
  intl,
  ...props
}) => {
  const { path } = useRouteMatch();
  const [openBackupModal, setBackupModalState] = useState(false);
  const [openSourceOperation, setOpenSourceOperation] = useState(false);

  const history = useHistory();

  const toggleBackupModal = () => {
    setBackupModalState(!openBackupModal);
  };

  const environmentUri = getEnvironmentURI(
    organizationId,
    projectId,
    environmentId
  );

  const onClickMoreAction = ({ id, event }) => {
    switch (id) {
      case MORE_ACTION_ID.BACKUP:
        if (environment.hasLink("#backup")) {
          event.preventDefault();
          document.activeElement.blur();
          toggleBackupModal();
        }
        break;
      case MORE_ACTION_ID.REDEPLOY:
        environment.hasLink("#redeploy") &&
          history.push(`${environmentUri}/actions/redeploy`);
        event.preventDefault();
        break;
      case MORE_ACTION_ID.SOURCE_OPS:
        setOpenSourceOperation(!openSourceOperation);
        event.preventDefault();
        break;
      case MORE_ACTION_ID.DELETE_ENVIRONMENT:
      //TODO DELETE delete environment
    }
  };

  return (
    <S.Wrapper>
      <FloatBox>
        <NavigationMenu
          project={project}
          environment={environment}
          environmentId={environmentId}
          organizationId={organizationId}
          projectId={projectId}
          {...props}
        />
        <S.ActionButtons
          className={`action-buttons${
            environment && environment.status === "inactive" ? " inactive" : ""
          }`}
        >
          {path === "/:organizationId/:projectId/:environmentId" && (
            <React.Fragment>
              <ActionButton
                id="environment-branch-btn"
                to={`${environmentUri}/actions/branch`}
                disabled={!environment.hasLink("#branch")}
                aria-label={intl.formatMessage({ id: "branch" })}
                expandWhen={showCompleteButtonsMQ}
              >
                <BranchIcon />{" "}
                {capitalize(intl.formatMessage({ id: "branch" }))}
              </ActionButton>

              <ActionButton
                id="environment-merge-btn"
                to={`${environmentUri}/actions/merge`}
                disabled={!environment.hasLink("#merge")}
                aria-label={intl.formatMessage({ id: "merge" })}
                expandWhen={showCompleteButtonsMQ}
              >
                <MergeIcon /> {capitalize(intl.formatMessage({ id: "merge" }))}
              </ActionButton>

              <ActionButton
                id="environment-synchronize-btn"
                to={`${environmentUri}/actions/sync`}
                disabled={!environment.hasLink("#synchronize")}
                aria-label={intl.formatMessage({ id: "sync" })}
                expandWhen={showCompleteButtonsMQ}
              >
                <SyncIcon /> {capitalize(intl.formatMessage({ id: "sync" }))}
              </ActionButton>

              {(path === "/:organizationId/:projectId/:environmentId" ||
                path ===
                  `/:organizationId/:projectId/:environmentId/backups`) && (
                <MoreEnvironmentAction
                  onClick={onClickMoreAction}
                  environment={environment}
                />
              )}
            </React.Fragment>
          )}

          <EnvironmentBackupModal
            isOpen={openBackupModal}
            closeModal={toggleBackupModal}
          />
          {process.env.ENABLE_SOURCE_OPERATION && (
            <SourceOperations
              organizationId={organizationId}
              projectId={projectId}
              environmentId={environmentId}
              isOpen={openSourceOperation}
              onClose={() => setOpenSourceOperation(false)}
            />
          )}
        </S.ActionButtons>
      </FloatBox>
    </S.Wrapper>
  );
};

EnvironmentNavBar.propTypes = {
  project: PropTypes.object,
  environment: PropTypes.object,
  intl: PropTypes.object,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  environmentId: PropTypes.string
};

export default injectIntl(EnvironmentNavBar);
