import { Map } from "immutable";

import { request } from "Libs/platform";
import config from "Constants/api_config";
import logger from "Libs/logger";
import { normalize } from "Libs/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export type Plan = {
  label: string;
  name: string;
  price: {
    formatted: string;
    amount: number;
    currency_code: string;
  };
};

type PlansResponse = {
  count: number;
  plans: Plan[];
  _links: {
    self: {
      title: string;
      href: string;
    };
    next?: {
      href: string;
    };
  };
};

const fetchPlans = async (
  href = `${config.api_url}/v1/plans`
): Promise<Plan[]> => {
  const resultFromHref: PlansResponse = await request(href, "GET").catch(
    (err: Error) => {
      logger(err);
    }
  );

  const { plans } = resultFromHref;

  if (resultFromHref._links.next) {
    return plans.concat(await fetchPlans(resultFromHref._links.next.href));
  }

  return plans;
};

export const getPlans = createAsyncThunk("plans/list", async () => {
  const planList = await fetchPlans();

  const plans = normalize(planList, "name");
  const planNamesBySize = planList.map(plan => plan.name);

  return {
    plans,
    planNamesBySize
  };
});

const plan = createSlice({
  name: "plan",
  // TODO: Map expects that values are all of the same type.
  initialState: Map<
    "data" | "errors" | "loading" | "planNamesBySize",
    unknown
  >(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPlans.pending, state => state.set("loading", true))
      .addCase(getPlans.fulfilled, (state, action) =>
        state
          .set("data", action.payload.plans)
          .set("planNamesBySize", action.payload.planNamesBySize)
          .set("loading", false)
      )
      .addCase(getPlans.rejected, (state, action) =>
        state.set("errors", action.error)
      );
  }
});

export default plan.reducer;
