import React, { FC } from "react";

import Button, { Props as ButtonProps } from "./Button";
import useMediaQuery from "Hooks/useMediaQuery";

export type ActionButtonProps = ButtonProps & {
  content: never;
  /** A valid media query which will decide when to display the text */
  expandWhen: string;
  /** The icon must be the first child of the component, the rest will be used
   * as text */
  children: Pick<ButtonProps, "children">;
};

/**
 * The action button displays an icon button until the provided media query
 * returns true, then will display the children next to the icon, behaving like
 * a mixed content button.
 */
const ActionButton: FC<ActionButtonProps> = ({
  expandWhen,
  children,
  ...props
}: ActionButtonProps) => {
  const content = useMediaQuery(expandWhen) ? "mixed" : "icon";

  const childrenArray = React.Children.toArray(children);

  // Assume the first child is going to be the icon
  const icon = childrenArray[0];
  // Assume the rest will be the text
  const text = childrenArray.slice(1);

  return (
    <Button
      {...props}
      content={content}
      onClick={(event: any) => {
        if (props.onClick) {
          props.onClick(event);
        }

        if (elementHasBlur(document.activeElement)) {
          document.activeElement.blur();
        }
      }}
    >
      {icon}
      {content === "mixed" && text}
    </Button>
  );
};

const elementHasBlur = (
  element: any
): element is HTMLButtonElement | HTMLAnchorElement =>
  typeof element.blur === "function";

export default ActionButton;
