import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { Button, ButtonWrapper } from "ds/Button";

import {
  Heading,
  OperationDescription,
  SourceOperationContainer
} from "../SourceOperation.style";

export const ConfigureOperationNotice = ({ onClose }) => {
  const formatMessage = useIntl().formatMessage;
  return (
    <SourceOperationContainer data-testid="configure-source-operation">
      <Heading>{formatMessage({ id: "source_ops.configure.title" })}</Heading>
      <div>
        <OperationDescription className="operation-notice">
          {formatMessage({ id: "source_ops.configure.message" })}
        </OperationDescription>
        <OperationDescription className="operation-notice">
          {formatMessage({ id: "source_ops.configure.learnmore" })}{" "}
          <a
            href={formatMessage({
              id: "links.documentation.source_operations"
            })}
            target="_blank"
            className="link"
          >
            {formatMessage({ id: "documentation" })}
          </a>
          .
        </OperationDescription>
      </div>
      <ButtonWrapper hasSpacing justifyContent="end">
        <Button variant="secondary" onClick={onClose}>
          {formatMessage({ id: "close" })}
        </Button>
      </ButtonWrapper>
    </SourceOperationContainer>
  );
};

ConfigureOperationNotice.propTypes = {
  onClose: PropTypes.func
};

export default ConfigureOperationNotice;
