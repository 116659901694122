import { fromJS, Map } from "immutable";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrganizationId } from "Libs/utils";
import getIsLoadingState, {
  getErrorState,
  setErrorState,
  setIsLoadingState
} from "Reducers/utils";

export const getOrganizationProfile = createAsyncThunk(
  "app/organization/profile",
  async ({ organizationId }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const profile = await client.getOrganizationProfile(
      getOrganizationId(getState, organizationId)
    );

    return profile;
  }
);

export const updateOrganizationProfile = createAsyncThunk(
  "app/organization/profile/update",
  async ({ organizationId, data }, { getState, rejectWithValue }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    try {
      const profile = await client.updateOrganizationProfile(
        getOrganizationId(getState, organizationId) || organizationId,
        data
      );
      return profile;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const setError = (state, { error, meta }) =>
  setIsLoadingState(state, meta.arg.organizationId, false).set(
    "errors",
    error.message
  );

const organizationProfile = createSlice({
  name: "organizationProfile",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {
    clearUpdatedOrganizationProfile: state => state.delete("update")
  },
  extraReducers: {
    [getOrganizationProfile.pending]: (state, { meta }) =>
      setIsLoadingState(state, meta.arg.organizationId),
    [getOrganizationProfile.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return setIsLoadingState(state, organizationId, false).setIn(
        ["data", organizationId],
        fromJS(action.payload)
      );
    },
    [getOrganizationProfile.rejected]: (state, action) =>
      setError(state, action),
    [updateOrganizationProfile.pending]: (state, { meta }) =>
      setIsLoadingState(state, ["update", meta.arg.organizationId]),
    [updateOrganizationProfile.fulfilled]: (state, { meta, payload }) =>
      setIsLoadingState(
        state,
        ["update", meta.arg.organizationId],
        false
      ).setIn(["update", meta.arg.organizationId], payload),
    [updateOrganizationProfile.rejected]: (state, { meta, payload }) => {
      const errorState = setErrorState(
        state,
        ["update", meta.arg.organizationId],
        payload
      );
      return setIsLoadingState(
        errorState,
        ["update", meta.arg.organizationId],
        false
      );
    }
  }
});

export const { clearUpdatedOrganizationProfile } = organizationProfile.actions;
export default organizationProfile.reducer;

export const organizationProfileSelector = (state, props) =>
  state.organizationProfile?.getIn(["data", props.organizationId]);

export const isUpdatingOrganizationProfileSelector = (state, organizationId) =>
  getIsLoadingState(state?.organizationProfile, ["update", organizationId]);

export const organizationProfileUpdateErrorSelector = (state, organizationId) =>
  getErrorState(state?.organizationProfile, ["update", organizationId]);

export const updatedOrganizationProfileSelector = (state, organizationId) =>
  state?.organizationProfile.getIn(["update", organizationId]);
