import React from "react";
import styled, { keyframes, css } from "styled-components";
import { getCSSVarString, SECTION } from "Libs/themes";

export const SHADOW_WIDTH = 360;

const slide = keyframes`
  0% {
    left: -${SHADOW_WIDTH}px;
  }
  100% {
    left: 100%;
  }
`;

export const Shadow = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  background: ${getCSSVarString(
      SECTION,
      "skeleton-gradien",
      "background-color"
    )}
    no-repeat center;
  z-index: 3;
  width: ${SHADOW_WIDTH}px;
  left: -${SHADOW_WIDTH}px;

  ${({ fallbackAnimation }) =>
    fallbackAnimation &&
    css`
      animation: ${slide} 2s linear infinite;
    `};
`;

export const Layout = styled.div`
  position: relative;
  overflow: hidden;
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};

  box-sizing: border-box;
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

/**
 * Some utility components to help quickly set up skeletons
 */
export const Square = styled(
  // eslint-disable-next-line no-unused-vars
  ({ height, width, fixedWidth, margin, background, padding, ...props }) => (
    <div {...props} />
  )
)`
  overflow: hidden;
  background-color: ${({ background }) =>
    background ||"var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)))"};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

  ${({ fixedWidth, width }) =>
    fixedWidth
      ? css`
          width: ${width};
        `
      : css`
          width: 100%;
          max-width: ${width};
          min-width: 0;
        `};
`;

export const Card = styled(Square)`
  background-color: ${({ background }) =>
    background ||"var(--section-snow-background-color,var(--section-snow,var(--snow)))"};
`;

export const Circle = styled(Square)`
  border-radius: 100%;
`;
