import React from "react";

export const Low: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.5 4.5 0 10 0C15.5 0 20 4.5 20 10C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10ZM3.7 9.3C3.3134 9.6866 3.3134 10.3134 3.7 10.7L7.2929 14.2929C7.68342 14.6834 8.31659 14.6834 8.70711 14.2929L16.3 6.7C16.6866 6.3134 16.6866 5.6866 16.3 5.3C15.9134 4.9134 15.2866 4.9134 14.9 5.3L8 12.2L5.1 9.3C4.7134 8.9134 4.0866 8.9134 3.7 9.3Z"
        fill="#23B7B7"
      />
    </svg>
  );
};

export default Low;
