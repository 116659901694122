import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const EnvMasterIcon = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`environment-main-icon-${uid}`}
    >
      <title id={`environment-main-icon-${uid}`}>Environment main icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 11.874C14.7252 11.4299 16 9.86384 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 9.86384 9.27477 11.4299 11 11.874V14.5452V17.5L6.5 17.5C5.94771 17.5 5.5 17.9477 5.5 18.5C5.5 19.0523 5.94771 19.5 6.5 19.5L11.929 19.5H17.5C18.0523 19.5 18.5 19.0523 18.5 18.5C18.5 17.9477 18.0523 17.5 17.5 17.5H13V14.5452V11.874ZM14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8Z"
        fill={color}
      />
    </svg>
  );
};

EnvMasterIcon.propTypes = {
  color: PropTypes.string
};

export default EnvMasterIcon;
