import React, { FC, SVGProps } from "react";

const Chevron: FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.2 6.79999C14.5 6.79999 14.7 6.89999 14.9 7.09999C15.3 7.49999 15.3 8.09999 14.9 8.49999L11.4 12L14.9 15.5C15.3 15.9 15.3 16.5 14.9 16.9C14.5 17.3 13.9 17.3 13.5 16.9L9.3 12.7C8.9 12.3 8.9 11.7 9.3 11.3L13.5 7.09999C13.7 6.89999 13.9 6.79999 14.2 6.79999Z"
      fill="#5F5E70"
    />
  </svg>
);

export default Chevron;
