import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { css, getCSSVarString, SECTION, INPUT } from "Libs/themes";
import { breakpoints } from "Libs/theme";

import Label from "Components/fields/Label";
import RequiredTag from "Components/fields/RequiredTag";

const InputFieldLayout = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  max-width: 100%;
  width: ${props => props.width || "264px"};
  margin-bottom: 16px;
  position: relative;

  @media (max-width: ${breakpoints[1]}) {
    width: 100%;
  }

  &.field-error {
    label {
      color:var(--section-red-color,var(--section-red,var(--red)));
    }
    .required-notice {
      background-color:var(--section-red-background-color,var(--section-red,var(--red)));
    }
    input {
      border-color:var(--input-red-border-color,var(--input-red,var(--red)));
    }
  }
  .error-alert {
    border: none;
    padding: 4px 0 0;
    .icon {
      display: none;
    }
    .message {
      margin-left: 0;
      font-size: 13px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .input-required {
    border-color:var(--input-red-border-color,var(--input-red,var(--red)));
  }
  .required-message,
  .error-message {
    font-weight: 400;
    margin-top: 8px;
    display: inline-block;
    color:var(--section-red-color,var(--section-red,var(--red)));
    font-size: 13px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &.is-disabled {
    label {
      opacity: 0.5;
    }
    input {
      cursor: not-allowed;
    }
  }
  input::placeholder {
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    opacity: 0.5;
  }
`;

const InputLayout = styled.div`
  position: relative;
  input {
    border: 1px solid var(--input-transparent-border-color,var(--input-transparent,var(--transparent)));
    border-radius: 2px;
    color:var(--input-night-color,var(--input-night,var(--night)));
    background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
    padding: 0 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    &:focus {
      padding: 0 15px;
      outline: none;
    }
    &.changed {
      border-color:var(--input-granite-border-color,var(--input-granite,var(--granite)));
      padding: 0 15px;
      box-shadow: none;
      background: none;
    }
    &.no-label {
      margin-top: 26px;
    }
  }
  .field-icon {
    position: absolute;
    left: ${props =>
      !props.iconAlign || props.iconAlign === "left" ? "8px" : "auto"};
    right: ${props => (props.iconAlign === "right" ? "8px" : "auto")};
    top: 38px;
    width: 22px;
    height: 16px;
    svg {
      max-width: 100%;
    }
  }
  &.with-icon input {
    padding-left: 46px;
  }
`;

class InputField extends React.Component {
  constructor(props) {
    super(props);
    this._input = null;
    this.isEmpty = this.isEmpty.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.autofocus = this.autofocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.state = {
      emptyRequired: false,
      isChanged: false
    };
  }

  componentDidMount() {
    this.autofocus();
  }

  componentDidUpdate() {
    this.autofocus();
  }

  autofocus() {
    if (this.props.autofocus) {
      this._input.focus();
    }
  }
  isEmpty(required, value) {
    required && !value && value !== 0
      ? this.setState({
          emptyRequired: true,
          isChanged: false
        })
      : this.setState({
          emptyRequired: false,
          isChanged: false
        });
  }

  changeValue() {
    this.setState({
      isChanged: true
    });
  }

  handleChange(e) {
    const { onChange, required } = this.props;
    this.isEmpty(required, e.target.value);
    onChange(e);
  }

  handlePaste(e) {
    if (this.props.onPaste) this.props.onPaste(e);
  }

  render() {
    const {
      className,
      error,
      placeholder,
      value = "",
      label,
      id,
      ariaLabel,
      required = true,
      type,
      isDisabled = false,
      hiddenLabel,
      icon,
      iconAlign,
      width,
      children,
      getRef,
      handleKeyPress = () => {}
    } = this.props;

    return (
      <InputFieldLayout
        width={width}
        className={`field input-field${
          error || this.state.emptyRequired ? " field-error" : ""
        }${isDisabled ? " is-disabled" : ""}${
          className ? " " + className + " field-" + className : ""
        }`}
      >
        <InputLayout
          className={`
            ${icon ? "with-icon" : ""}${iconAlign ? " icon-" + iconAlign : ""}`}
        >
          {label && (
            <Label
              className={`label${hiddenLabel ? " hidden-label" : ""}`}
              htmlFor={id}
            >
              {label}
              {required ? <RequiredTag id={id} /> : ""}
            </Label>
          )}
          {icon && <span className="field-icon">{icon}</span>}
          {children ? (
            children
          ) : (
            <input
              id={id}
              type={type ? type : "text"}
              placeholder={!this.state.isChanged ? placeholder || label : ""}
              value={value}
              onChange={this.handleChange}
              onClick={this.changeValue}
              onKeyPress={handleKeyPress}
              name={id}
              aria-label={ariaLabel}
              required={required}
              aria-required={required}
              className={`${this.state.emptyRequired ? " input-required" : ""}${
                this.state.isChanged ? " changed" : ""
              }`}
              onBlur={() => this.isEmpty(required, value)}
              onPaste={this.handlePaste}
              disabled={isDisabled}
              ref={c => {
                this._input = c;
                if (getRef) getRef(c);
              }}
            />
          )}
          {error ? (
            <div className="error-message">{error}</div>
          ) : (
            this.state.emptyRequired && (
              <div className="required-message">
                <FormattedMessage id="field.is_required" values={{ label }} />
              </div>
            )
          )}
        </InputLayout>
      </InputFieldLayout>
    );
  }
}

InputField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  getRef: PropTypes.func,
  handleKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  useMask: PropTypes.bool,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  ariaLabel: PropTypes.string,
  required: PropTypes.bool,
  intl: PropTypes.object,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  hiddenLabel: PropTypes.bool,
  icon: PropTypes.node,
  children: PropTypes.node,
  iconAlign: PropTypes.string,
  autofocus: PropTypes.bool,
  width: PropTypes.string
};

export default injectIntl(InputField);
