import React, { FC } from "react";

import CloseIcon from "Icons/CloseIcon";

import * as S from "./CloseButton.styles";

const CloseButton: FC<{ className: string }> = ({
  children,
  className,
  ...props
}) => (
  <S.Layout className={className}>
    {children && <S.Text>{children}</S.Text>}
    <S.Icon variant="secondary" {...props} content="icon">
      <CloseIcon />
    </S.Icon>
  </S.Layout>
);

export default CloseButton;
